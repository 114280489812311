import { injectable } from "inversify";

import container from "../wires/Bootstrapper";
import SERVICE_IDENTIFIER from "../wires/Identifiers"
import * as Api from "../constants/ApiUrlConstants";

import { ModelBase } from "../models/ModelBase";
import { AuthenticationModelOutput } from "../models/AccountModels";
import { IApiService } from "./shared/ApiService";
import { IApiServiceExt } from "./shared/ApiServiceExt";


export interface IAccountService {
    ResetPasswordCliente(Cliente: string): Promise<ModelBase>
    Login(UserName: string, Password: string): Promise<AuthenticationModelOutput>
}

@injectable()
export class AccountService implements IAccountService {

    apiService: IApiService
    apiServiceExt: IApiServiceExt

    constructor() {
        this.apiService = container.get(SERVICE_IDENTIFIER.API_SERVICE)
        this.apiServiceExt = container.get(SERVICE_IDENTIFIER.API_SERVICE_EXT)
    }

    async ResetPasswordCliente(Cliente: string): Promise<ModelBase> {
        return this.apiServiceExt.getAsync<ModelBase>(Api.ResetPasswordCliente, { Cliente })
    }


    async Login(UserName: string, Password: string): Promise<AuthenticationModelOutput> {
        return this.apiService.postAsync<AuthenticationModelOutput>(Api.Login, { UserName, Password })
    }
}