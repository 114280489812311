import * as React from 'react'

export class AppContextModel {
    Richieste: number
    RefreshRichieste: () => void
}

const defultModel: AppContextModel = {
    Richieste: 0,
    RefreshRichieste: () => { }
}

export const AppContext = React.createContext<AppContextModel>(defultModel)
