import * as React from 'react'

import SERVICE_IDENTIFIER from '../../wires/Identifiers'
import container from '../../wires/Bootstrapper'

import { PagedBaseState } from '../../models/PagedBaseState'
import { StatLinkClienteModel } from '../../models/StatModels'
import { IStatProdottoService } from '../../services/StatProdottoService'
import { Table } from 'antd'
import { IdSearcher } from '../../models/IdSearcher'

interface TableStatsProdottoProps {
    pageSize: number
    prodottoId: string
}

class TableStatsProdottoState extends PagedBaseState<StatLinkClienteModel> {
    loading: boolean = false
}

export class TableStatsProdotto extends React.Component<TableStatsProdottoProps, TableStatsProdottoState> {

    service: IStatProdottoService

    constructor(props) {
        super(props)

        this.service = container.get(SERVICE_IDENTIFIER.STAT_PRODOTTO_SERVICE)
        this.state = new TableStatsProdottoState()
    }

    static defaultProps = {
        pageSize: 10
    }

    componentDidMount() {
        this.onSearch(1)
    }

    onSearch = async (page: number) => {
        this.setState({ loading: true })

        let model: IdSearcher = {
            Id: this.props.prodottoId,
            Pagina: page,
            Righe: this.props.pageSize
        }
        let resp = await this.service.SearchClientiStats(model)
        this.setState({
            Data: resp,
            Page: page,
            loading: false
        })
    }

    render() {
        return (
            <Table
                dataSource={this.state.Data.Data}
                rowKey={it => it.Id}
                loading={this.state.loading}
                pagination={{
                    onChange: (page) => this.onSearch(page),
                    pageSize: this.props.pageSize,
                    total: this.state.Data.Total,
                    current: this.state.Page
                }}>
                <Table.Column dataIndex="Cliente" title="Cliente" />
                {/*<Table.Column dataIndex="Letto" title="Stato visualizzazione" render={value =>
                    <TwoStateTag value={value} on="VISUALIZZATA" off="NON VISUALIZZATA" />} />*/}
                <Table.Column dataIndex="Cliccato" title="Reindirizzamenti su ecommerce" />
            </Table>
        )
    }
}