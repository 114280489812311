import * as React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { Row, Col, PageHeader, List, Input, Button, Form } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { FormInstance } from 'antd/lib/form'

import { IClienteService } from '../../services/ClienteService'
import SERVICE_IDENTIFIER from '../../wires/Identifiers'
import container from '../../wires/Bootstrapper'

import { ClienteModel } from '../../models/ClienteModels'
import { PagedBaseState } from '../../models/PagedBaseState'
import { ClienteSearcher } from '../../models/ClienteSearcher'

import { TTAvatarName } from '../TTAvatarName'

export class PageClientiComp extends React.Component<RouteComponentProps, PagedBaseState<ClienteModel>> {

    service: IClienteService
    formRef = React.createRef<FormInstance>();

    constructor(props) {
        super(props)

        this.service = container.get(SERVICE_IDENTIFIER.CLIENTE_SERVICE)
        this.state = new PagedBaseState<ClienteModel>();
    }

    componentDidMount() {
        this.onSearch(1)
    }

    onSearch = async (page: number) => {
        const data = this.formRef.current.getFieldsValue()

        let model = new ClienteSearcher()
        model.Righe = 10
        model.Pagina = page
        model.FullText = data["Nominativo"]
        model.Email = data["Email"]
        model.Telefono = data["Telefono"]

        let resp = await this.service.Search(model)
        this.setState({
            Data: resp,
            Page: page
        });
    }

    onClienteClick = id => {
        this.props.history.push(`Cliente/${id}`)
    }

    render() {
        return <>
            <PageHeader title="Clienti">
                <br />
                <Form ref={this.formRef}>
                    <Row gutter={40}>
                        <Col xl={8} xs={24}>
                            <Form.Item name="Nominativo" label="Nominativo">
                                <Input allowClear onPressEnter={() => this.onSearch(1)} />
                            </Form.Item>
                        </Col>
                        <Col xl={8} xs={24}>
                            <Form.Item name="Telefono" label="Telefono">
                                <Input allowClear onPressEnter={() => this.onSearch(1)} />
                            </Form.Item>
                        </Col>
                        <Col xl={8} xs={24}>
                            <Form.Item name="Email" label="Email">
                                <Input allowClear onPressEnter={() => this.onSearch(1)} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="end">
                        <Col>
                            <Button type="primary" onClick={() => this.onSearch(1)} icon={<SearchOutlined />}>Cerca</Button>
                        </Col>
                    </Row>
                </Form>
                <br />
                <List
                    dataSource={this.state.Data.Data}
                    rowKey={e => e.Id}
                    pagination={{
                        onChange: (page) => this.onSearch(page),
                        pageSize: 10,
                        total: this.state.Data.Total,
                        current: this.state.Page
                    }}
                    renderItem={item => (
                        <List.Item key={item.Id}>
                            <List.Item.Meta
                                avatar={<TTAvatarName size="small" nome={item.Nome} />}
                                title={<a onClick={() => this.onClienteClick(item.Id)}>{`${item.Nome} ${item.Cognome}`}</a>}
                            />
                        </List.Item>
                    )}>
                </List>
            </PageHeader>
        </>
    }
}

export const PageClienti = withRouter(PageClientiComp)