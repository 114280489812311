import * as React from 'react'
import { Row, Col, Input, Table } from 'antd';

import SERVICE_IDENTIFIER from '../../wires/Identifiers'
import container from '../../wires/Bootstrapper'

import { PagedBaseState } from '../../models/PagedBaseState';
import { IClienteService } from '../../services/ClienteService';
import { ClienteModel } from '../../models/ClienteModels';
import { ClienteSearcher } from '../../models/ClienteSearcher';

interface PickerClientiProps {
    selectedCustomers?: (data: Array<string>) => void
    pageSize?: number
    searcher?: ClienteSearcher
}

class PickerClientiState extends PagedBaseState<ClienteModel> {
}

export class PickerClienti extends React.Component<PickerClientiProps, PickerClientiState> {
    service: IClienteService

    constructor(props) {
        super(props)

        this.service = container.get(SERVICE_IDENTIFIER.CLIENTE_SERVICE)
        this.state = new PickerClientiState();
    }

    static defaultProps = {
        pageSize: 10,
        searcher: new ClienteSearcher()
    }

    componentDidMount() {
        this.onSearch(1)
    }

    componentDidUpdate(prevProps: PickerClientiProps, prevState: PickerClientiState) {
        if (prevProps.searcher != this.props.searcher)
            this.onSearch(1)
    }

    onSearch = async (page: number) => {
        let model = { ...this.props.searcher }
        model.Righe = this.props.pageSize
        model.Pagina = page
        model.FullText = this.state.Searcher

        let resp = await this.service.Search(model)
        this.setState({
            Data: resp,
            Page: page
        })
    }

    isSelectable = () => {
        return this.props.selectedCustomers != null
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        if (this.isSelectable())
            this.props.selectedCustomers(selectedRowKeys)
    };

    render() {
        const rowSelection = this.isSelectable() == true
            ? { onChange: this.onSelectChange }
            : null

        return (
            <>
                <Row>
                    <Col offset={1} span={22}>
                        <Input.Search
                            placeholder="Cerca cliente"
                            onChange={e => this.setState({ Searcher: e.target.value })}
                            onSearch={() => this.onSearch(1)}
                            value={this.state.Searcher}
                            enterButton />
                    </Col>
                </Row>
                <br />
                <Table
                    showHeader={false}
                    dataSource={this.state.Data.Data}
                    rowKey={it => it.Id}
                    rowSelection={rowSelection}
                    pagination={{
                        onChange: (page) => this.onSearch(page),
                        pageSize: this.props.pageSize,
                        total: this.state.Data.Total,
                        current: this.state.Page
                    }}>
                    <Table.Column render={(_, item: ClienteModel) => `${item.Nome} ${item.Cognome}`} />
                </Table>
            </>
        )
    }
}