import * as React from 'react'
import { PageHeader, Descriptions, Button, Statistic, Row, Col, Tabs, Modal } from 'antd'
import { StopOutlined, CloseCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import { RouteComponentProps } from 'react-router'

import container from '../../wires/Bootstrapper'
import SERVICE_IDENTIFIER from '../../wires/Identifiers'

import { ClienteModel } from '../../models/ClienteModels'
import { StatLetturaModel } from '../../models/StatModels'
import { RichiesteClienteModel } from '../../models/RichiesteModels'

import { ClienteRouteParams } from './ClienteRouteParams'
import { IClienteService } from '../../services/ClienteService'
import { IAccountService } from '../../services/AccountService'
import { IStatComunicazioneService } from '../../services/StatComunicazioneService'
import { IStatPromozioneService } from '../../services/StatPromozioneService'
import { IRichiestaService } from '../../services/RichiestaService'

import { TabComunicazioni } from './TabComunicazioni'
import { TabPromozioni } from './TabPromozioni'
import { TabRichieste } from './TabRichieste'

class PageClienteState extends ClienteModel {
    StatComunicazioni: StatLetturaModel
    StatPromozioni: StatLetturaModel
    StatRichieste: RichiesteClienteModel
}

export class PageCliente extends React.Component<RouteComponentProps<ClienteRouteParams>, PageClienteState> {

    service: IClienteService
    accountService: IAccountService
    statComunicazione: IStatComunicazioneService
    statPromozione: IStatPromozioneService
    richiesta: IRichiestaService

    constructor(props) {
        super(props)

        this.service = container.get(SERVICE_IDENTIFIER.CLIENTE_SERVICE)
        this.accountService = container.get(SERVICE_IDENTIFIER.ACCOUNT_SERVICE)
        this.statComunicazione = container.get(SERVICE_IDENTIFIER.STAT_COMUNICAZIONE_SERVICE)
        this.statPromozione = container.get(SERVICE_IDENTIFIER.STAT_PROMOZIONE_SERVICE)
        this.richiesta = container.get(SERVICE_IDENTIFIER.RICHIESTA_SERVICE)

        let stato = new PageClienteState()
        stato.StatRichieste = new RichiesteClienteModel()
        stato.StatComunicazioni = new StatLetturaModel()
        stato.StatPromozioni = new StatLetturaModel()
        this.state = stato
    }

    componentDidMount() {
        this.load()
    }

    load = async () => {
        let resp = await this.service.Get(this.props.match.params.id)
        if (resp.IsValid)
            this.setState(resp)

        let comunicazioni = await this.statComunicazione.GetRead(this.props.match.params.id)
        if (comunicazioni.IsValid)
            this.setState({ StatComunicazioni: comunicazioni })

        let promozioni = await this.statPromozione.GetRead(this.props.match.params.id)
        if (promozioni.IsValid)
            this.setState({ StatPromozioni: promozioni })

        let richieste = await this.richiesta.Count(this.props.match.params.id)
        if (richieste.IsValid)
            this.setState({ StatRichieste: richieste })
    }

    onResetClick = async () => {
        Modal.confirm({
            title: 'Attenzione',
            content: 'Vuoi ripristinare la password del cliente? Il cliente verrà notificato e gli sarà assegnata una password temporanea da cambiare al primo accesso',
            onOk: async () => {
                await this.accountService.ResetPasswordCliente(this.props.match.params.id)
            }
        })
    }

    onSospendiClick = async () => {
        Modal.confirm({
            title: 'Attenzione',
            content: 'Vuoi sospendere il cliente? Il cliente non sarà più visibile all\'interno dell\'applicativo',
            onOk: async () => {
                await this.service.SospendiCliente(this.props.match.params.id)
            }
        })
    }

    getTitle = () => {
        return (
            <>
                {/*<TTAvatarName size="large" nome={this.state.Nome} cognome={this.state.Cognome} />*/}
                {this.state.Nome} {this.state.Cognome}
            </>
        )
    }

    renderStats = () => {
        return <Row>
            <Col span={6}>
                <Statistic title="Comunicazioni lette" value={this.state.StatComunicazioni.Letti} suffix={`/ ${this.state.StatComunicazioni.Totale}`} />
            </Col>
            <Col span={6}>
                <Statistic title="Promozioni visualizzate" value={this.state.StatPromozioni.Letti} suffix={`/ ${this.state.StatPromozioni.Totale}`} />
            </Col>
            <Col span={6}>
                <Statistic title="Richieste create" value={this.state.StatRichieste.Create} />
            </Col>
        </Row>
    }

    render() {
        return <>
            <PageHeader title={this.getTitle()} onBack={() => this.props.history.goBack()} extra={[
                <Button key="reset" type="primary" icon={<StopOutlined />} onClick={() => this.onResetClick()}>Resetta password</Button>,
                <Button key="reset" type="ghost" icon={<CloseCircleOutlined />} onClick={() => this.onSospendiClick()}>Sospendi Cliente</Button>
            ]}>
                <Descriptions size="small" column={2}>
                    <Descriptions.Item label="Email">
                        <a target="_blank" href={`mailto:${this.state.Email}`}>{this.state.Email}</a>
                    </Descriptions.Item>
                    <Descriptions.Item label="Telefono Cellulare">
                        <a target="_blank" href={`tel:${this.state.TelefonoCellulare}`}>{this.state.TelefonoCellulare}</a>
                    </Descriptions.Item>
                    <Descriptions.Item label="Comune">{this.state.Comune}</Descriptions.Item>
                    <Descriptions.Item label="Provincia">{this.state.Provincia}</Descriptions.Item>
                    <Descriptions.Item label="Data di nascita">
                        {moment(this.state.DataNascita).format('L')} ({this.state.Eta} anni)
                    </Descriptions.Item>
                    <Descriptions.Item label="Sesso">
                        {this.state.Sesso}
                    </Descriptions.Item>
                    <Descriptions.Item label="Codice Cliente">
                        {this.state.CodiceCliente}
                    </Descriptions.Item>
                </Descriptions>
                <br />
                {this.renderStats()}
                <br />
                <Tabs>
                    <Tabs.TabPane tab="Comunicazioni" key="comu">
                        <TabComunicazioni />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Promozioni" key="promo">
                        <TabPromozioni />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Richieste" key="richie">
                        <TabRichieste />
                    </Tabs.TabPane>
                </Tabs>
            </PageHeader>
        </>
    }
}