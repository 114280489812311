import * as React from 'react'
import { Form, Modal, Descriptions, InputNumber, Row, Col, Alert } from 'antd'

import { TTModalProps } from '../TTModalProps'
import { RenderClienteSearcher } from '../../models/ClienteSearcher'
import { TTFormItem } from '../TTFormItem'
import { TTSelect } from '../TTSelect'
import { renderSelectKeyValue, renderSelectList } from '../SelectOptionRenderUtils'
import { IClienteService } from '../../services/ClienteService'
import SERVICE_IDENTIFIER from '../../wires/Identifiers'
import container from '../../wires/Bootstrapper'
import { PickerClienti } from '../tools/PickerClienti'


class ModalFiltroClientiState extends RenderClienteSearcher {

}

export class ModalFiltroClienti extends React.Component<TTModalProps, ModalFiltroClientiState> {

    service: IClienteService

    constructor(props) {
        super(props)

        this.state = new ModalFiltroClientiState()
        this.service = container.get(SERVICE_IDENTIFIER.CLIENTE_SERVICE)
    }

    componentDidMount() {
        this.load()
    }

    load = async () => {
        let resp = await this.service.RenderSearch()
        this.setState(resp)
    }

    onOk = () => {
        this.props.do(this.state)
        this.props.closeModal()
    }

    render() {
        return (
            <Modal
                title="Filtro clienti"
                visible={this.props.visible}
                onCancel={() => this.props.closeModal()}
                onOk={() => this.onOk()}
                width={800}>
                <Alert type="info" message="Puoi filtrare i clienti per provincia oppure per comune ma non puoi selezionare entrambe le voci" showIcon />
                <br />
                <Descriptions title="Filtri di selezione clienti" />
                <Form>
                    <TTFormItem label="Comune">
                        <TTSelect value={this.state.Comune} onChange={e => this.setState({ Comune: e as string })} disabled={this.state.Provincia != null} showSearch allowClear>
                            {renderSelectList(this.state.ComuneList)}
                        </TTSelect>
                    </TTFormItem>
                        <TTFormItem label="Provincia">
                        <TTSelect value={this.state.Provincia} onChange={e => this.setState({ Provincia: e as string })} disabled={this.state.Comune != null} showSearch allowClear>
                            {renderSelectList(this.state.ProvinciaList)}
                        </TTSelect>
                    </TTFormItem>
                    <TTFormItem label="Sesso">
                        <TTSelect value={this.state.Sesso} onChange={e => this.setState({ Sesso: e as string })} allowClear>
                            {renderSelectKeyValue(this.state.SessoList)}
                        </TTSelect>
                    </TTFormItem>
                    <TTFormItem label="Età">
                        <Row>
                            <Col span={10}>
                                <TTSelect value={this.state.ComparazioneEta} onChange={e => this.setState({ ComparazioneEta: e as string })} allowClear>
                                    {renderSelectKeyValue(this.state.ComparazioneEtaList)}
                                </TTSelect>
                            </Col>
                            <Col offset={1} span={3}>
                                <InputNumber min={2} max={150} value={this.state.Eta} onChange={(value) => this.setState({ Eta: value as number })} />
                            </Col>
                        </Row>
                    </TTFormItem>
                </Form>

                <Descriptions title="Visualizza clienti inclusi" />
                <PickerClienti searcher={this.state} />
            </Modal>
        )
    }
}