import * as React from 'react'
import './TTContent.less'


export const TTContent = ({ children, extra }) => {
    return (
        <div className="tt-content">
            <div className="main">{children}</div>
            <div className="tt-extra">{extra}</div>
        </div>
    )
}
