import * as React from 'react'
import { AvatarProps } from 'antd/lib/avatar'
import { TTAvatar } from './TTAvatar'

interface TTAvatarNameProps {
    nome: string
    cognome?: string
}

export const TTAvatarName: React.FC<AvatarProps & TTAvatarNameProps> = (props) => {

    const { nome, cognome, ...others } = props 
    let n = nome == null ? '' : nome[0]
    let c = cognome == null ? '' : cognome[0]
    
    return (
        <TTAvatar {...others}>{`${n}${c}`}</TTAvatar>
    )
}