import { ModelBase, KeyValueModel } from "./ModelBase";

export class ComunicazioneBaseModel extends ModelBase {
    Titolo: string
    Descrizione: string
}

export class ComunicazioneModel extends ComunicazioneBaseModel {
    Data: Date
}

export class ComunicazioneClienteModel extends ComunicazioneModel {
    Letto: boolean
}

export class CreaComunicazioneModel extends ComunicazioneBaseModel {
    Destinatario: string
    Clienti: Array<string>
}

export class RenderCreaComunicazioneModel extends CreaComunicazioneModel {
    DestinatarioList: Array<KeyValueModel>
}
