import * as React from 'react'
import { Select } from 'antd'
import { KeyValueModel } from '../models/ModelBase';

export const renderSelectKeyValue = (source: Array<KeyValueModel>) => {
    if (source == null) return
    let items = source.map((it) =>
        <Select.Option key={it.Key} value={it.Key}>{it.Value}</Select.Option>
    )

    return items;
}

export const renderSelectList = (source) => {
    if (source == null) return

    let items = source.map(item =>
        <Select.Option key={item} value={item}>{item}</Select.Option>
    )
    return items
}
