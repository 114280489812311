import * as React from 'react'
import { Button, PageHeader, Form, Input, Descriptions } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import { RouteComponentProps, withRouter } from 'react-router'

import { IComunicazioneService } from '../../services/ComunicazioneService'
import SERVICE_IDENTIFIER from '../../wires/Identifiers'
import container from '../../wires/Bootstrapper'
import { RenderCreaComunicazioneModel } from '../../models/ComunicazioniModels'
import { TTSelect } from '../TTSelect'
import { renderSelectKeyValue } from '../SelectOptionRenderUtils'
import { TTFormItem } from '../TTFormItem'
import { PickerClienti } from '../tools/PickerClienti'
import { COMUNICAZIONE_DESTINATARIO_SELEZIONE } from '../../constants/SystemParamConstants'

class PageCreaComunicazioneState extends RenderCreaComunicazioneModel {
    VisualizzaClienti: boolean
}

export class PageCreaComunicazioneComp extends React.Component<RouteComponentProps, PageCreaComunicazioneState> {

    service: IComunicazioneService

    constructor(props) {
        super(props)

        this.state = new PageCreaComunicazioneState()
        this.service = container.get(SERVICE_IDENTIFIER.COMUNICAZIONE_SERVICE)
    }

    componentDidMount() {
        this.renderPage()
    }

    renderPage = async () => {
        let resp = await this.service.RenderCrea()
        this.setState(resp)
    }

    onSalvaClick = async () => {
        let resp = await this.service.Crea(this.state)
        if (resp.IsValid)
            this.props.history.push("/Comunicazioni")
    }

    onDestinatarioChange = (id: string) => {
        this.setState({
            Destinatario: id,
            VisualizzaClienti: id == COMUNICAZIONE_DESTINATARIO_SELEZIONE
        })
    }

    renderSelezioneClienti = () => {
        if (this.state.VisualizzaClienti == false)
            return <></>

        return (
            <PageHeader title={<Descriptions title="Seleziona uno o più clienti da notificare" />}>
                <PickerClienti selectedCustomers={data => this.setState({ Clienti: data })} />
            </PageHeader>
        )

    }

    render() {
        return (
            <>
                <PageHeader
                    title="Crea Comunicazione"
                    onBack={() => this.props.history.goBack()}
                    extra={[
                        <Button key="add" type="primary" icon={<SaveOutlined />} onClick={() => this.onSalvaClick()}>Salva</Button>
                    ]}>
                    <br />
                    <Form>
                        <TTFormItem label="Titolo" required={true}>
                            <Input value={this.state.Titolo} onChange={e => this.setState({ Titolo: e.target.value })} />
                        </TTFormItem>
                        <TTFormItem label="Descrizione" required={true}>
                            <Input.TextArea value={this.state.Descrizione} onChange={e => this.setState({ Descrizione: e.target.value })} />
                        </TTFormItem>
                        <TTFormItem label="Destinatario">
                            <TTSelect value={this.state.Destinatario} onChange={e => this.onDestinatarioChange(e as string)}>
                                {renderSelectKeyValue(this.state.DestinatarioList)}
                            </TTSelect>
                        </TTFormItem>
                    </Form>
                </PageHeader>
                {this.renderSelezioneClienti()}
            </>
        )
    }
}

export const PageCreaComunicazione = withRouter(PageCreaComunicazioneComp)