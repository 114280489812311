import * as React from 'react'
import { List } from 'antd'
import moment from 'moment'
import { RouteComponentProps, withRouter } from 'react-router'

import { PromozioneClienteModel } from '../../models/PromozioniModels'
import { PagedBaseState } from '../../models/PagedBaseState'
import { IdSearcher } from '../../models/IdSearcher'

import SERVICE_IDENTIFIER from '../../wires/Identifiers'
import container from '../../wires/Bootstrapper'

import { ClienteRouteParams } from './ClienteRouteParams'
import { IPromozioneService } from '../../services/PromozioneService'

import { TTAvatar } from '../TTAvatar'
import { Promozione } from '../tools/TTIcons'
import { TwoStateTag } from '../tools/TwoStateTag'

class TabPromozioniState extends PagedBaseState<PromozioneClienteModel> {

}

export class TabPromozioniComp extends React.Component<RouteComponentProps<ClienteRouteParams>, TabPromozioniState> {

    service: IPromozioneService

    constructor(props) {
        super(props)

        this.state = new TabPromozioniState()
        this.service = container.get(SERVICE_IDENTIFIER.PROMOZIONE_SERVICE)
    }

    componentDidMount() {
        this.onSearch(1)
    }

    onSearch = async (page: number) => {
        let id = this.props.match.params.id
        let model: IdSearcher = {
            Id: id,
            Righe: 10,
            Pagina: page
        }
        let resp = await this.service.SearchByCliente(model)
        this.setState({
            Data: resp,
            Page: page
        })
    }

    onPromozioneClick = id => {
        this.props.history.push(`/Promozione/${id}`)
    }

    render() {
        return (
            <List
                dataSource={this.state.Data.Data}
                rowKey={it => it.Id}
                pagination={{
                    onChange: (page) => this.onSearch(page),
                    pageSize: 10,
                    total: this.state.Data.Total,
                    current: this.state.Page
                }}
                renderItem={item => (
                    <List.Item key={item.Id} actions={[<TwoStateTag key="tag" value={item.Letto} on="VISUALIZZATA" off="NON VISUALIZZATA" />]}>
                        <List.Item.Meta
                            avatar={<TTAvatar icon={<Promozione />} />}
                            title={item.IsActive
                                ? <a onClick={() => this.onPromozioneClick(item.Id)}>{item.Titolo}</a>
                                : <span style={{ color: "#DFDFDF", cursor:"pointer"}}
                                            onClick={() => this.onPromozioneClick(item.Id)}>{item.Titolo}</span>}
                            description={moment(item.DataCreazione).format('LLL')} />
                    </List.Item>
                )}>
            </List>
        )
    }
}

export const TabPromozioni = withRouter(TabPromozioniComp)
