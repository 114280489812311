import { injectable } from "inversify"

import container from '../wires/Bootstrapper'
import SERVICE_IDENTIFIER from '../wires/Identifiers'

import * as Api from "../constants/ApiUrlConstants"
import { IApiService } from "./shared/ApiService"
import { IApiServiceExt } from "./shared/ApiServiceExt"

import { ModelBase } from "../models/ModelBase"
import { CreaPromozioneModel, PromozioneModel, PromozioneBaseModel, PromozioneClienteModel } from "../models/PromozioniModels"
import { SearcherBaseModel } from "../models/SearcherBaseModel"
import { ProjectionPagedModel } from "../models/ProjectionPageModel"
import { IdSearcher } from "../models/IdSearcher"

export interface IPromozioneService {
    RenderCrea(): Promise<CreaPromozioneModel>
    Crea(data: CreaPromozioneModel): Promise<ModelBase>
    Get(Id: string): Promise<PromozioneModel>
    Search(searcher: SearcherBaseModel): Promise<ProjectionPagedModel<PromozioneBaseModel>>
    SearchByCliente(searcher: IdSearcher): Promise<ProjectionPagedModel<PromozioneClienteModel>>
    UploadResource(id: string, file: File): Promise<ModelBase>
    Close(Id: string): Promise<PromozioneModel>
}

@injectable()
export class PromozioneService implements IPromozioneService {

    apiService: IApiService
    apiServiceExt: IApiServiceExt

    constructor() {
        this.apiService = container.get(SERVICE_IDENTIFIER.API_SERVICE)
        this.apiServiceExt = container.get(SERVICE_IDENTIFIER.API_SERVICE_EXT)
    }

    async RenderCrea(): Promise<CreaPromozioneModel> {
        return this.apiService.getAsync<CreaPromozioneModel>(Api.RenderCreaPromozione, {})
    }

    async Crea(data: CreaPromozioneModel): Promise<ModelBase> {
        return this.apiServiceExt.postAsync<ModelBase>(Api.CreaPromozione, data)
    }

    async Get(Id: string): Promise<PromozioneModel> {
        return this.apiService.getAsync<PromozioneModel>(Api.GetPromozione, { Id })
    }

    async Close(Id: string): Promise<PromozioneModel> {
        return this.apiService.getAsync<PromozioneModel>(Api.ClosePromozione, { Id })
    }

    async Search(searcher: SearcherBaseModel): Promise<ProjectionPagedModel<PromozioneBaseModel>> {
        return this.apiService.postAsync<ProjectionPagedModel<PromozioneBaseModel>>(Api.SearchPromozione, searcher)
    }

    async SearchByCliente(searcher: IdSearcher): Promise<ProjectionPagedModel<PromozioneClienteModel>> {
        return this.apiService.postAsync<ProjectionPagedModel<PromozioneClienteModel>>(Api.SearchPromozioneByCliente, searcher)
    }

    async UploadResource(id: string, file: File): Promise<ModelBase> {
        let formData = new FormData()
        formData.append("Id", id)
        formData.append("File", file)

        return this.apiService.postAsync<ModelBase>(Api.UploadResourcePromozione, formData)
    }
}