
export const mapEta = {
    COMPARAZIONE_ETA_MAGGIORE: "> di",
    COMPARAZIONE_ETA_MINORE: "< di",
    COMPARAZIONE_ETA_UGUALE: ""
}

export const mapSesso = {
    CLIENTE_SESSO_M: "M",
    CLIENTE_SESSO_F: "F"
}