import * as React from 'react'
import { Tag } from 'antd'

interface TwoStateTagProps {
    value: boolean
    on: string
    off: string
}

export const TwoStateTag: React.FC<TwoStateTagProps> = (props) => {
    return props.value == true
        ? <Tag color="green">{props.on}</Tag>
        : <Tag color="red">{props.off}</Tag>
}