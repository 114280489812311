import Cookies from 'universal-cookie';
import { injectable } from 'inversify';
import { ILoginModel } from '../../models/LoginModels';

const cookies = new Cookies();

export interface IAuthService {
    isAuthenticated(): boolean;
    getToken(): string;
    setToken(token: string)
    signInAndReload(model: ILoginModel): void;
    signIn(model: ILoginModel, postLogin: () => void): void;
    signOut(postLogout: () => void): void;
    signOutAndReload(): void;
    restart(): void;
}


@injectable()
export class AuthService implements IAuthService {
    authKey: string = `${process.env.REACT_APP_NAME}_auth`

    isAuthenticated(): boolean {
        return cookies.get(this.authKey) != null;
    }

    getToken(): string {
        return cookies.get(this.authKey);
    }

    setToken(token: string) {
        cookies.set(this.authKey, token, { path: '/' });
    }

    signInAndReload(model: ILoginModel): void {
        this.signIn(model, () => { this.restart(); });
    }

    signIn(model: ILoginModel, postLogin: () => void): void {
        this.setToken(model.Token)
        postLogin();
    }

    signOut(postLogout: () => void): void {
        cookies.remove(this.authKey);
        if (postLogout != null) postLogout();
    }

    signOutAndReload(): void {
        this.signOut(() => { this.restart(); });
    }

    restart(): void {
        window.location.href = '/';
    }
}
