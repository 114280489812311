import { injectable } from "inversify";

import container from '../wires/Bootstrapper'
import SERVICE_IDENTIFIER from '../wires/Identifiers'

import * as Api from "../constants/ApiUrlConstants";
import { IApiService } from "./shared/ApiService";
import { IdSearcher } from "../models/IdSearcher";
import { ProjectionPagedModel } from "../models/ProjectionPageModel";
import { StatClienteModel, StatLetturaModel } from "../models/StatModels";

export interface IStatComunicazioneService {
    SearchLetture(data: IdSearcher): Promise<ProjectionPagedModel<StatClienteModel>>
    GetRead(ClienteId: string): Promise<StatLetturaModel>
    GetAllRead(): Promise<StatLetturaModel>
}

@injectable()
export class StatComunicazioneService implements IStatComunicazioneService {

    apiService: IApiService;

    constructor() {
        this.apiService = container.get(SERVICE_IDENTIFIER.API_SERVICE);
    }

    async SearchLetture(data: IdSearcher): Promise<ProjectionPagedModel<StatClienteModel>> {
        return this.apiService.postAsync<ProjectionPagedModel<StatClienteModel>>(Api.SearchStatComunicazione, data)
    }

    async GetRead(ClienteId: string): Promise<StatLetturaModel> {
        return this.apiService.getAsync<StatLetturaModel>(Api.GetReadStatComunicazione, { ClienteId })
    }

    async GetAllRead(): Promise<StatLetturaModel> {
        return this.apiService.getAsync<StatLetturaModel>(Api.GetAllReadStatComunicazione, {})
    }
}