import { SearcherBaseModel } from "./SearcherBaseModel";
import { KeyValueModel } from "./ModelBase";

export class ClienteSearcher extends SearcherBaseModel {
    Telefono: string
    Email: string
    Comune: string
    Provincia: string
    Sesso: string
    Eta: number
    ComparazioneEta: string
}

export class RenderClienteSearcher extends ClienteSearcher {
    ComuneList: Array<string>
    ProvinciaList: Array<string>
    SessoList: Array<KeyValueModel>
    ComparazioneEtaList: Array<KeyValueModel>
}