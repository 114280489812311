// Account
export const Login = '/WebAccount/Login'
export const ResetPasswordCliente = '/WebAccount/ResetPasswordCliente'

// File
export const GetResource = 'File/GetResource'
export const DeleteResource = '/File/DeleteResource'

// Clienti
export const GetCliente = '/WebCliente/Get'
export const RenderSearchCliente = '/WebCliente/RenderSearch'
export const SearchCliente = '/WebCliente/Search'
export const SospendiCliente = '/WebCliente/SospendiCliente'

// Comunicazioni
export const GetComunicazione = '/WebComunicazione/Get'
export const SearchComunicazione = '/WebComunicazione/Search'
export const SearchComunicazioneByCliente = '/WebComunicazione/SearchByCliente'
export const RenderCreaComunicazione = '/WebComunicazione/RenderCrea'
export const CreaComunicazione = '/WebComunicazione/Crea'
export const ModificaComunicazione = '/WebComunicazione/Modifica'

// Statistiche Comunicazioni
export const SearchStatComunicazione = '/WebStatComunicazione/SearchLetture'
export const GetReadStatComunicazione = '/WebStatComunicazione/GetRead'
export const GetAllReadStatComunicazione = '/WebStatComunicazione/GetAllRead'

// Promozione
export const RenderCreaPromozione = '/WebPromozione/RenderCrea'
export const CreaPromozione = '/WebPromozione/Crea'
export const GetPromozione = '/WebPromozione/Get'
export const ClosePromozione = '/WebPromozione/Close'
export const SearchPromozione = '/WebPromozione/Search'
export const SearchPromozioneByCliente = '/WebPromozione/SearchByCliente'
export const UploadResourcePromozione = '/WebPromozione/UploadResource'

// Statistiche Promozioni
export const SearchStatPromozione = '/WebStatPromozione/SearchClientiStats'
export const GetReadStatPromozione = '/WebStatPromozione/GetRead'
export const GetAllReadStatPromozione = '/WebStatPromozione/GetAllRead'

// Statistiche Prodotti
export const SearchStatProdotto = '/WebStatProdotto/SearchClientiStats'

// Richieste
export const GetRichiesta = '/WebRichiesta/Get'
export const SearchRichiesta = '/WebRichiesta/Search'
export const SearchRichiestaByCliente = '/WebRichiesta/SearchByCliente'
export const CountRichiesta = '/WebRichiesta/Count'
export const CloseRichiesta = '/WebRichiesta/Close'

// Statistiche
export const GetClientiCompleanno = '/WebStatistica/GetClientiCompleanno'
export const GetRichiesteStats = '/WebStatistica/GetRichieste'
export const GetClientiStats = '/WebStatistica/GetClienti'
export const GetClicks = '/WebStatistica/GetClicks'
