import * as React from 'react'
import { Modal, Form, Input } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { RcCustomRequestOptions } from 'antd/lib/upload/interface';

import SERVICE_IDENTIFIER from '../../wires/Identifiers';
import container from '../../wires/Bootstrapper';

import { TTFormItem } from '../TTFormItem'
import { ProdottoModel } from '../../models/ProdottoModel';
import { IPromozioneService } from '../../services/PromozioneService';
import { TTImageUpload } from '../TTUpload';
import { TTModalProps } from './../TTModalProps';

interface ModalProdottoProps extends TTModalProps {
    selectedProdotto: ProdottoModel
    promozioneId: string
}

class ModalProdottoState extends ProdottoModel { }

export class ModalProdotto extends React.Component<ModalProdottoProps, ModalProdottoState> {

    formRef = React.createRef<FormInstance>()
    service: IPromozioneService

    constructor(props) {
        super(props)

        this.state = new ModalProdottoState()
        this.service = container.get(SERVICE_IDENTIFIER.PROMOZIONE_SERVICE)
    }

    componentDidMount() {
        this.setState(this.props.selectedProdotto)
    }

    closeModal = () => {
        this.formRef.current.resetFields()
        this.props.closeModal()
    }

    onSave = async () => {
        try {
            let data = await this.formRef.current.validateFields()

            // Sovrascrivo lo stato con i valori della form
            let result = { ...this.state }
            result.Nome = data.Nome
            result.Link = data.Link

            this.props.do(result)
            this.closeModal()
        } catch (err) {
            console.log(err)
        }
    }

    uploadImage = async (options: RcCustomRequestOptions) => {
        const { onSuccess, onError, file } = options

        var resp = await this.service.UploadResource(this.props.promozioneId, file)
        if (resp.IsValid) {
            this.setState({ ResourceId: resp.Id })
            onSuccess(resp, file)
        } else {
            const error = new Error(resp.Notifies[0].Message);
            onError(error)
        }
    };

    render() {
        const { selectedProdotto } = this.props
        return (
            <Modal
                destroyOnClose
                title="Prodotto"
                visible={this.props.visible}
                onCancel={this.closeModal}
                onOk={this.onSave}>
                <Form ref={this.formRef}>
                    <TTFormItem
                        label="Nome"
                        name="Nome"
                        initialValue={selectedProdotto.Nome}
                        rules={[{ required: true, message: 'Il nome del prodotto è obbligatorio' }]}>
                        <Input />
                    </TTFormItem>
                    <TTFormItem
                        label="Link"
                        name="Link"
                        initialValue={selectedProdotto.Link}
                        tooltip="Inserisci qui il link all'ecommerce"
                        rules={[{ required: true, type: 'url', message: 'Il valore inserito non è un url' }]}>
                        <Input />
                    </TTFormItem>
                    <TTFormItem label="Immagine">
                        <TTImageUpload
                            listType="picture-card"
                            resourceId={this.state.ResourceId}
                            customRequest={options => this.uploadImage(options)} />
                    </TTFormItem>
                </Form>
            </Modal>
        )
    }
}