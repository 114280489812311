import * as React from 'react'
import { Layout, Menu } from 'antd'
import { TTSiderMenuItem } from './TTSiderMenuItem'
import { RichiesteSidebarIcon } from './tools/RichiesteSidebarIcon'
import { Promozione, Statistiche, Comunicazione, Cliente } from './tools/TTIcons'

const { Sider } = Layout

export const AppSidebar: React.FC<any> = (props) => {

    return (
        <Sider
            theme="light"
            breakpoint="sm"
            width={220}
            collapsible>
            <Menu mode="inline" defaultSelectedKeys={['home']}>
                <TTSiderMenuItem key="home" path="/" icon={<Statistiche />}>Statistiche</TTSiderMenuItem>
                <TTSiderMenuItem key="clie" path="/Clienti" icon={<Cliente />}>Clienti</TTSiderMenuItem>
                <TTSiderMenuItem key="comu" path="/Comunicazioni" icon={<Comunicazione />}>Comunicazioni</TTSiderMenuItem>
                <TTSiderMenuItem key="prom" path="/Promozioni" icon={<Promozione />}>Promozioni</TTSiderMenuItem>
                <TTSiderMenuItem key="supp" path="/Richieste" icon={<RichiesteSidebarIcon />}>Richieste clienti</TTSiderMenuItem>
            </Menu>
        </Sider>
    )
}