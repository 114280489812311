import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';

import App from './AppLayout';
import registerServiceWorker from './registerServiceWorker';

import it from 'antd/es/locale/it_IT';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <ConfigProvider locale={it}>
            <App />
        </ConfigProvider>
  </BrowserRouter>,
  rootElement);

registerServiceWorker();

