import * as React from 'react'
import { TTSimpleModalProps } from '../TTModalProps'
import { Modal } from 'antd'
import { ImageModal } from '../ImageModal'
import SERVICE_IDENTIFIER from '../../wires/Identifiers'
import container from '../../wires/Bootstrapper'
import { IFileService } from '../../services/FileService'

interface TTImageModalProps {
    resourceId: string
}

export const TTImageModal: React.FC<TTSimpleModalProps & TTImageModalProps> = (props) => {

    let service: IFileService = container.get(SERVICE_IDENTIFIER.FILE_SERVICE)
    const { resourceId, ...others } = props

    return (
        <ImageModal imgUrl={service.GetImageUrl(resourceId)} {...others} />
    )
}