import * as React from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { PageHeader, Row, Col, List, Button, Avatar } from 'antd'
import { RouteComponentProps, withRouter } from 'react-router'
import Search from 'antd/lib/input/Search'
import moment from 'moment'

import { SearcherBaseModel } from '../../models/SearcherBaseModel'
import { PagedBaseState } from '../../models/PagedBaseState'
import SERVICE_IDENTIFIER from '../../wires/Identifiers'
import container from '../../wires/Bootstrapper'
import { ComunicazioneModel } from '../../models/ComunicazioniModels'
import { IComunicazioneService } from '../../services/ComunicazioneService'
import { ComunicazioneRouteParams } from './ComunicazioneRouteParams'
import { TTAvatar } from '../TTAvatar'
import { Comunicazione } from '../tools/TTIcons'

export class PageComunicazioniComp extends React.Component<RouteComponentProps<ComunicazioneRouteParams>, PagedBaseState<ComunicazioneModel>> {

    service: IComunicazioneService

    constructor(props) {
        super(props)

        this.service = container.get(SERVICE_IDENTIFIER.COMUNICAZIONE_SERVICE)
        this.state = new PagedBaseState<ComunicazioneModel>();
    }

    componentDidMount() {
        this.onSearch(1)
    }

    onSearch = async (page: number) => {
        let model: SearcherBaseModel = {
            Righe: 10,
            Pagina: page,
            FullText: this.state.Searcher
        }
        let resp = await this.service.Search(model)
        this.setState({
            Data: resp,
            Page: page
        });
    }

    onComunicazioneClick = id => {
        this.props.history.push(`Comunicazione/${id}`)
    }

    onCreaClick = () => {
        this.props.history.push(`Comunicazione`)
    }

    render() {
        return (
            <>
                <PageHeader title="Comunicazioni" extra={[
                    <Button key="add" type="primary" icon={<PlusOutlined />} onClick={() => this.onCreaClick()}>Crea</Button>
                ]}>
                    <br />
                    <Row>
                        <Col offset={1} span={22} >
                            <Search
                                placeholder="Cerca comunicazione"
                                onChange={e => this.setState({ Searcher: e.target.value })}
                                onSearch={() => this.onSearch(1)}
                                onPressEnter={() => this.onSearch(1)}
                                value={this.state.Searcher}
                                enterButton />
                        </Col>
                    </Row>
                    <br />
                    <List
                        dataSource={this.state.Data.Data}
                        rowKey="Id"
                        pagination={{
                            onChange: (page) => this.onSearch(page),
                            pageSize: 10,
                            total: this.state.Data.Total,
                            current: this.state.Page
                        }}
                        renderItem={item => (
                            <List.Item key={item.Id}>
                                <List.Item.Meta
                                    avatar={<TTAvatar icon={<Comunicazione />} />}
                                    title={<a onClick={() => this.onComunicazioneClick(item.Id)}>{item.Titolo}</a>}
                                    description={moment(item.Data).format('LLL')}
                                />
                            </List.Item>
                        )}>
                    </List>
                </PageHeader>
            </>
        )
    }
}

export const PageComunicazioni = withRouter(PageComunicazioniComp)