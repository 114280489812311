import * as React from 'react'
import { TTSimpleModalProps } from './TTModalProps'
import { Modal } from 'antd'

interface ImageModalProps {
    imgUrl: string
}

export const ImageModal: React.FC<TTSimpleModalProps & ImageModalProps> = (props) => {

    return (
        <Modal
            visible={props.visible}
            title="Immagine"
            footer={null}
            onCancel={() => props.closeModal()}>
            <img style={{ width: '100%' }} alt="Immagine" src={props.imgUrl} />
        </Modal>
    )
}