import { Modal, message } from 'antd';
import { injectable } from 'inversify';
import { Notify } from '../../models/ModelBase';
import * as React from 'react';


export interface INotifyService {
    error(title: string, content: string): void;
    validation(title: string, content: string): void;
    success(msg: string): void
    errorWithNotifies(title: string, notifies: Array<Notify>)
}

@injectable()
export class NotifyService implements INotifyService {
    OKTEXT = 'Chiudi';

    success(msg: string): void {
        message.success(msg)
    }

    errorWithNotifies(title: string, notifies: Array<Notify>) {
        let content = notifies.map(it => <span key={ it.Message } > { it.Message } <br /> </span>)
        this.error(title, content)
    }

    error(title, content) {
        Modal.error(
            {
                title,
                content,
                okText: this.OKTEXT
            });
    }

    validation(title, content) {
        Modal.warning(
            {
                title,
                content,
                okText: this.OKTEXT
            });
    }
};

