import * as React from 'react'
import { PageHeader, Descriptions } from 'antd'
import { RouteComponentProps, withRouter } from 'react-router'
import moment from 'moment'

import SERVICE_IDENTIFIER from '../../wires/Identifiers'
import container from '../../wires/Bootstrapper'

import { IComunicazioneService } from '../../services/ComunicazioneService'
import { ComunicazioneRouteParams } from './ComunicazioneRouteParams'
import { ComunicazioneModel } from '../../models/ComunicazioniModels'
import { TableLetturaComunicazione } from './TableLetturaComunicazione'

class PageComunicazioneState extends ComunicazioneModel {

}

export class PageComunicazioneComp extends React.Component<RouteComponentProps<ComunicazioneRouteParams>, PageComunicazioneState> {

    service: IComunicazioneService

    constructor(props) {
        super(props)

        this.service = container.get(SERVICE_IDENTIFIER.COMUNICAZIONE_SERVICE)
        this.state = new PageComunicazioneState()
    }

    componentDidMount() {
        this.load()
    }

    load = async () => {
        let id = this.props.match.params.id
        let resp = await this.service.Get(id)
        if (resp.IsValid)
            this.setState(resp)
    }

    render() {
        return (
            <>
                <PageHeader
                    onBack={() => this.props.history.goBack()}
                    title={this.state.Titolo}>
                    <>
                        {this.state.Descrizione}
                        <br />
                        <br />
                        <Descriptions>
                            <Descriptions.Item label="Creata il">{moment(this.state.Data).format('LLL')}</Descriptions.Item>
                        </Descriptions>

                        <br />
                        <Descriptions title="Visualizzazioni" />
                        <TableLetturaComunicazione comunicazioneId={this.props.match.params.id} />
                    </>
                </PageHeader>
            </>
        )
    }
}

export const PageComunicazione = withRouter(PageComunicazioneComp)