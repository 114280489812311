import { ModelBase } from "./ModelBase";
import { Guid } from "guid-typescript";

export class ProdottoModel extends ModelBase {

    constructor() {
        super()

        this.Id = Guid.create().toString()
    }

    Nome: string
    Link: string
    ResourceId: string
}
