import * as React from 'react'
import { PageHeader, Row, Col, Input, List, Badge } from 'antd'
import { withRouter, RouteComponentProps } from 'react-router'
import moment from 'moment'

import SERVICE_IDENTIFIER from '../../wires/Identifiers'
import container from '../../wires/Bootstrapper'

import { PagedBaseState } from '../../models/PagedBaseState'
import { RichiestaModel } from '../../models/RichiesteModels'
import { IRichiestaService } from '../../services/RichiestaService'
import { SearcherBaseModel } from '../../models/SearcherBaseModel'
import { AppContext } from '../AppContext'
import { AvatarRichieste } from './AvatarRichieste'

class PageRichiesteState extends PagedBaseState<RichiestaModel> {

}

export class PageRichiesteComp extends React.Component<RouteComponentProps, PageRichiesteState> {

    service: IRichiestaService
    context!: React.ContextType<typeof AppContext>

    constructor(props) {
        super(props)

        this.state = new PageRichiesteState()
        this.service = container.get(SERVICE_IDENTIFIER.RICHIESTA_SERVICE)
    }

    componentDidMount() {
        this.onSearch(1)
        this.context.RefreshRichieste()
    }

    onSearch = async (page: number) => {
        let model: SearcherBaseModel = {
            Righe: 10,
            Pagina: page,
            FullText: this.state.Searcher
        }
        let resp = await this.service.Search(model)
        this.setState({
            Data: resp,
            Page: page
        })
    }

    onRichiestaClick = id => {
        this.props.history.push(`Richiesta/${id}`)
    }

    render() {
        return (
            <PageHeader title="Richieste">
                <br />
                <Row>
                    <Col offset={1} span={22}>
                        <Input.Search
                            placeholder="Cerca richiesta"
                            onChange={e => this.setState({ Searcher: e.target.value })}
                            onSearch={() => this.onSearch(1)}
                            value={this.state.Searcher}
                            enterButton />
                    </Col>
                </Row>
                <br />
                <List
                    dataSource={this.state.Data.Data}
                    rowKey="Id"
                    pagination={{
                        onChange: (page) => this.onSearch(page),
                        pageSize: 10,
                        total: this.state.Data.Total,
                        current: this.state.Page
                    }}
                    renderItem={item => (
                        <List.Item key={item.Id}>
                            <List.Item.Meta
                                avatar={<AvatarRichieste letto={item.Letto} />}
                                title={<a onClick={() => this.onRichiestaClick(item.Id)}>{item.Titolo}</a>}
                                description={`${moment(item.Data).format('LLL')} da ${item.Cliente}`} />
                        </List.Item>
                    )}>
                </List>
            </PageHeader>
        )
    }
}

PageRichiesteComp.contextType = AppContext

export const PageRichieste = withRouter(PageRichiesteComp)