import * as React from 'react'
import { PageHeader, Row, Col, Statistic, List, Typography } from 'antd'
import moment from 'moment'

import { IStatisticaService } from '../../services/StatisticaService'
import container from '../../wires/Bootstrapper'
import SERVICE_IDENTIFIER from '../../wires/Identifiers'
import { RichiesteStats, ClientiStats } from '../../models/StatisticaModels'
import { GiftOutlined, MessageOutlined } from '@ant-design/icons'
import { AppContext } from '../AppContext'
import { TTAvatarName } from '../TTAvatarName'
import { ClienteModel } from '../../models/ClienteModels'
import { StatLetturaModel } from '../../models/StatModels'
import { IStatPromozioneService } from '../../services/StatPromozioneService'
import { IStatComunicazioneService } from '../../services/StatComunicazioneService'
import { Cliente } from '../tools/TTIcons'

class PageStatisticaState {
    Richieste: RichiesteStats
    Clienti: ClientiStats
    ClientiAnni: Array<ClienteModel>
    Comunicazioni: StatLetturaModel
    Promozioni: StatLetturaModel
    Click: number
}

export class PageStatistica extends React.Component<any, PageStatisticaState> {

    service: IStatisticaService
    promService: IStatPromozioneService
    comService: IStatComunicazioneService
    context!: React.ContextType<typeof AppContext>

    constructor(props) {
        super(props)

        this.service = container.get(SERVICE_IDENTIFIER.STATISTICA_SERVICE)
        this.promService = container.get(SERVICE_IDENTIFIER.STAT_PROMOZIONE_SERVICE)
        this.comService = container.get(SERVICE_IDENTIFIER.STAT_COMUNICAZIONE_SERVICE)

        let stato = new PageStatisticaState()
        stato.Clienti = new ClientiStats()
        stato.Richieste = new RichiesteStats()
        stato.Comunicazioni = new StatLetturaModel()
        stato.Promozioni = new StatLetturaModel()
        this.state = stato
    }

    componentDidMount() {
        this.load()
        this.context.RefreshRichieste()
    }

    load = async () => {
        let clienti = await this.service.GetClientiStats()
        if (clienti.IsValid)
            this.setState({ Clienti: clienti })

        let richieste = await this.service.GetRichiesteStats()
        if (richieste.IsValid)
            this.setState({ Richieste: richieste })

        let clientiAnni = await this.service.GetClientiCompleanno()
        if (clientiAnni.IsValid)
            this.setState({ ClientiAnni: clientiAnni.Clienti })

        let click = await this.service.GetClicks()
        if (clientiAnni.IsValid)
            this.setState({ Click: click.Totale })

        let promozioni = await this.promService.GetAllRead()
        if (promozioni.IsValid)
            this.setState({ Promozioni: promozioni })

        let comunicazioni = await this.comService.GetAllRead()
        if (comunicazioni.IsValid)
            this.setState({ Comunicazioni: comunicazioni })
    }

    render() {
        return (
            <PageHeader title="Statistiche">
                <Row>
                    <Col span={8}>
                        <Statistic title="Richieste da leggere" value={this.state.Richieste.Aperte} prefix={<><MessageOutlined />&nbsp;</>} />
                    </Col>
                    <Col span={8}>
                        <Statistic title="Clienti registrati" value={this.state.Clienti.Registrati} prefix={<Cliente />} />
                    </Col>
                    <Col span={8}>
                        <Statistic title="Compleanni questo mese" value={this.state.Clienti.CompionoAnni} prefix={<GiftOutlined />} />
                    </Col>
                </Row>
                <br />
                <br />
                <Row>
                    <Col span={8}>
                        <Statistic title="Comunicazioni visualizzate" value={this.state.Comunicazioni.Letti} suffix={`/ ${this.state.Comunicazioni.Totale}`} />
                    </Col>
                    <Col span={8}>
                        <Statistic title="Promozioni visualizzate" value={this.state.Promozioni.Letti} suffix={`/ ${this.state.Promozioni.Totale}`} />
                    </Col>
                    <Col span={8}>
                        <Statistic title="Redirect da app a ecommerce" value={this.state.Click} />
                    </Col>
                </Row>
                <br />
                <br />
                <div style={{ paddingTop: '16px' }}>
                    <Typography.Text strong style={{ color: 'black' }}>Compiono gli anni questo mese</Typography.Text>
                    <br />
                    <List
                        dataSource={this.state.ClientiAnni}
                        rowKey={it => it.Id}
                        pagination={{
                            pageSize: 10
                        }}
                        renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<TTAvatarName nome={item.Nome} />}
                                    title={`${item.Nome} ${item.Cognome}`}
                                    description={`${moment(item.DataNascita).format('LL')} (${item.Eta} anni)`}
                                />
                            </List.Item>
                        )}>
                    </List>
                </div>
            </PageHeader>
        )
    }
}

PageStatistica.contextType = AppContext