//export const COMUNICAZIONE_DESTINATARIO_GROUP = "COMUNICAZIONE_DESTINATARIO";
export const COMUNICAZIONE_DESTINATARIO_TUTTI = "COMUNICAZIONE_DESTINATARIO_TUTTI"
export const COMUNICAZIONE_DESTINATARIO_SELEZIONE = "COMUNICAZIONE_DESTINATARIO_SELEZIONE"

export const PROMOZIONE_DESTINATARIO_TUTTI = "PROMOZIONE_DESTINATARIO_TUTTI"
export const PROMOZIONE_DESTINATARIO_SELEZIONE = "PROMOZIONE_DESTINATARIO_SELEZIONE"
export const PROMOZIONE_DESTINATARIO_FILTRO = "PROMOZIONE_DESTINATARIO_FILTRO"

//export const COMPARAZIONE_ETA_GROUP = "COMPARAZIONE_ETA"
export const COMPARAZIONE_ETA_MAGGIORE = "COMPARAZIONE_ETA_MAGGIORE"
export const COMPARAZIONE_ETA_MINORE = "COMPARAZIONE_ETA_MINORE"
export const COMPARAZIONE_ETA_UGUALE = "COMPARAZIONE_ETA_UGUALE"

export const CLIENTE_SESSO_M = "CLIENTE_SESSO_M"
export const CLIENTE_SESSO_F = "CLIENTE_SESSO_F"