import "reflect-metadata";
import { Container } from "inversify";
import SERVICE_IDENTIFIER from "./Identifiers";

import { IAuthService, AuthService } from "../services/security/AuthService";
import { IApiService, ApiService } from "../services/shared/ApiService";
import { INotifyService, NotifyService } from "../services/shared/NotifyService";
import { IApiServiceExt, ApiServiceExt } from "../services/shared/ApiServiceExt";
import { IClienteService, ClienteService } from "../services/ClienteService";
import { IComunicazioneService, ComunicazioneService } from "../services/ComunicazioneService";
import { IStatComunicazioneService, StatComunicazioneService } from "../services/StatComunicazioneService";
import { IPromozioneService, PromozioneService } from "../services/PromozioneService";
import { IStatPromozioneService, StatPromozioneService } from "../services/StatPromozioneService";
import { IStatProdottoService, StatProdottoService } from "../services/StatProdottoService";
import { IFileService, FileService } from "../services/FileService";
import { IAccountService, AccountService } from "../services/AccountService";
import { IRichiestaService, RichiestaService } from "../services/RichiestaService";
import { IStatisticaService, StatisticaService } from "../services/StatisticaService";

let container = new Container();

container.bind<IApiService>(SERVICE_IDENTIFIER.API_SERVICE).to(ApiService);
container.bind<IApiServiceExt>(SERVICE_IDENTIFIER.API_SERVICE_EXT).to(ApiServiceExt);
container.bind<IAuthService>(SERVICE_IDENTIFIER.AUTH_SERVICE).to(AuthService);
container.bind<INotifyService>(SERVICE_IDENTIFIER.NOTIFY).to(NotifyService);

container.bind<IAccountService>(SERVICE_IDENTIFIER.ACCOUNT_SERVICE).to(AccountService);
container.bind<IClienteService>(SERVICE_IDENTIFIER.CLIENTE_SERVICE).to(ClienteService);
container.bind<IComunicazioneService>(SERVICE_IDENTIFIER.COMUNICAZIONE_SERVICE).to(ComunicazioneService);
container.bind<IStatComunicazioneService>(SERVICE_IDENTIFIER.STAT_COMUNICAZIONE_SERVICE).to(StatComunicazioneService)

container.bind<IPromozioneService>(SERVICE_IDENTIFIER.PROMOZIONE_SERVICE).to(PromozioneService);
container.bind<IStatPromozioneService>(SERVICE_IDENTIFIER.STAT_PROMOZIONE_SERVICE).to(StatPromozioneService);
container.bind<IStatProdottoService>(SERVICE_IDENTIFIER.STAT_PRODOTTO_SERVICE).to(StatProdottoService);

container.bind<IRichiestaService>(SERVICE_IDENTIFIER.RICHIESTA_SERVICE).to(RichiestaService);
container.bind<IStatisticaService>(SERVICE_IDENTIFIER.STATISTICA_SERVICE).to(StatisticaService);

container.bind<IFileService>(SERVICE_IDENTIFIER.FILE_SERVICE).to(FileService);

export default container;