import { injectable } from "inversify"

import container from '../wires/Bootstrapper'
import SERVICE_IDENTIFIER from '../wires/Identifiers'

import * as Api from "../constants/ApiUrlConstants"
import { IApiService } from "./shared/ApiService"
import { ModelBase } from "../models/ModelBase"

export interface IFileService {
    GetImageUrl(resourceId: string): string
    Delete(Id: string): Promise<ModelBase>
}

@injectable()
export class FileService implements IFileService {

    apiService: IApiService

    constructor() {
        this.apiService = container.get(SERVICE_IDENTIFIER.API_SERVICE)
    }

    GetImageUrl(resourceId: string): string {
        return `/api/${Api.GetResource}?Id=${resourceId}`
    }

    async Delete(Id: string): Promise<ModelBase> {
        return this.apiService.getAsync<ModelBase>(Api.DeleteResource, { Id });
    }
}