import * as React from 'react'
import SERVICE_IDENTIFIER from '../../wires/Identifiers'
import container from '../../wires/Bootstrapper'
import { IStatPromozioneService } from '../../services/StatPromozioneService'
import { PagedBaseState } from '../../models/PagedBaseState'
import { StatLinkClienteModel } from '../../models/StatModels'
import { IdSearcher } from '../../models/IdSearcher'
import { Table } from 'antd'
import { TwoStateTag } from '../tools/TwoStateTag'

interface TableStatsPromozioneProps {
    pageSize: number
    promozioneId: string
    showRedirect: boolean
}

class TableStatsPromozioneState extends PagedBaseState<StatLinkClienteModel> {
    loading: boolean = false
}

export class TableStatsPromozione extends React.Component<TableStatsPromozioneProps, TableStatsPromozioneState> {

    service: IStatPromozioneService

    constructor(props) {
        super(props)

        this.service = container.get(SERVICE_IDENTIFIER.STAT_PROMOZIONE_SERVICE)
        this.state = new TableStatsPromozioneState()
    }

    static defaultProps = {
        pageSize: 10
    }

    componentDidMount() {
        this.onSearch(1)
    }

    onSearch = async (page: number) => {
        this.setState({ loading: true })

        let model: IdSearcher = {
            Id: this.props.promozioneId,
            Pagina: page,
            Righe: this.props.pageSize
        }
        let resp = await this.service.SearchClientiStats(model)
        this.setState({
            Data: resp,
            Page: page,
            loading: false
        })
    }

    getColumns = () => {
        let columns = [
            <Table.Column dataIndex="Cliente" title="Cliente" key="col1" />,
            <Table.Column dataIndex="Letto" title="Stato visualizzazione" key="col2" render={value =>
                <TwoStateTag value={value} on="VISUALIZZATA" off="NON VISUALIZZATA" />} />
        ];

        if (this.props.showRedirect)
            columns.push(<Table.Column dataIndex="Cliccato" title="Reindirizzamenti su ecommerce" key="col3" />)

        return columns
    }

    render() {
        return (
            <Table
                dataSource={this.state.Data.Data}
                rowKey={it => it.Id}
                loading={this.state.loading}
                pagination={{
                    onChange: (page) => this.onSearch(page),
                    pageSize: this.props.pageSize,
                    total: this.state.Data.Total,
                    current: this.state.Page
                }}>
                {this.getColumns()}
            </Table>
        )
    }
}