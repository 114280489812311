import * as React from 'react'
import { MailOutlined, UsergroupAddOutlined, CreditCardOutlined, AreaChartOutlined, UserOutlined, MessageOutlined } from '@ant-design/icons'

export const Account = () => {
    return (<UserOutlined />)
}

export const Cliente = () => {
    return (<UsergroupAddOutlined />)
}

export const Comunicazione = () => {
    return (<MailOutlined />)
}

export const Promozione = () => {
    return (<CreditCardOutlined />)
}

export const Richiesta = () => {
    return (<MessageOutlined />)
}

export const Statistiche = () => {
    return (<AreaChartOutlined />)
}
