import { ApiService, IApiService } from './ApiService';
import { injectable } from "inversify";

export interface IApiServiceExt extends IApiService {
    getAsync<T>(url: string, params: any, successMessage?: string): Promise<T>
    postAsync<T>(url: string, params: any, successMessage?: string): Promise<T>
}

@injectable()
export class ApiServiceExt extends ApiService implements IApiServiceExt {

    successMessage: string = null

    constructor() {
        super();
        this.client.interceptors.response.use(this.handleResponeSucces);
    }

    protected handleResponeSucces = (response) => {

        if (response.data.IsValid) {
            if (this.successMessage == null) {
                this.successMessage = 'Operazione avvenuta con successo'
            }

            this._notifyService.success(this.successMessage)
            this.successMessage = null
        }
        return response;
    }

    public async getAsync<T>(url: string, params: any, successMessage?: string): Promise<T> {
        this.successMessage = successMessage
        return super.getAsync<T>(url, params)
    }

    public async postAsync<T>(url: string, params: any, successMessage?: string): Promise<T> {
        this.successMessage = successMessage
        return await super.postAsync(url, params)
    }

}

