import * as React from 'react'
import { PageHeader, Form, Input, Descriptions, List, Button, Modal } from 'antd'
import { RcCustomRequestOptions } from 'antd/lib/upload/interface'
import { EditOutlined, DeleteOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons'
import { RouteComponentProps, withRouter } from 'react-router'

import container from '../../wires/Bootstrapper'
import SERVICE_IDENTIFIER from '../../wires/Identifiers'
import { PROMOZIONE_DESTINATARIO_SELEZIONE, PROMOZIONE_DESTINATARIO_FILTRO } from '../../constants/SystemParamConstants'

import { IPromozioneService } from '../../services/PromozioneService'
import { IFileService } from '../../services/FileService'

import { RenderCreaPromozioneModel } from '../../models/PromozioniModels'
import { PickerClienti } from '../tools/PickerClienti'
import { ProdottoModel } from '../../models/ProdottoModel'
import { ClienteSearcher } from '../../models/ClienteSearcher'

import { renderSelectKeyValue } from '../SelectOptionRenderUtils'

import { TTFormItem } from '../TTFormItem'
import { TTSelect } from '../TTSelect'
import { ModalProdotto } from './ModalProdotto'
import { ModalFiltroClienti } from './ModalFiltroClienti'
import { RiepilogoFiltroClienti } from './RiepilogoFiltroClienti'
import { TTImageUpload } from '../TTUpload'
import { TTImage } from '../tools/TTImage'

class PageCreaPromozioneState extends RenderCreaPromozioneModel {
    visibleProdotto: boolean = false
    selectedProdotto: ProdottoModel

    visibleFiltroClienti: boolean = false
}

export class PageCreaPromozioneComp extends React.Component<RouteComponentProps, PageCreaPromozioneState> {

    service: IPromozioneService
    fileService: IFileService

    constructor(props) {
        super(props)

        this.service = container.get(SERVICE_IDENTIFIER.PROMOZIONE_SERVICE)
        this.fileService = container.get(SERVICE_IDENTIFIER.FILE_SERVICE)
        this.state = new PageCreaPromozioneState()
    }

    componentDidMount() {
        this.renderPage()
    }

    renderPage = async () => {
        let resp = await this.service.RenderCrea()
        this.setState(resp)
    }

    onSalvaClick = async () => {
        let resp = await this.service.Crea(this.state)
        if (resp.IsValid)
            this.props.history.push("/Promozioni")
    }

    onRemoveProdotto = (item: ProdottoModel) => {
        Modal.confirm({
            title: 'Attenzione',
            content: 'Sei sicuro di voler cancellare il prodotto?',
            onOk: () => {
                var prodotti = [...this.state.Prodotti]
                var index = prodotti.findIndex(c => c.Id == item.Id)
                if (index != -1) {
                    let prodotto = prodotti[index]
                    this.deleteProdotto(prodotto)

                    prodotti.splice(index, 1)
                }

                this.setState({ Prodotti: prodotti })
            }
        })
    }

    deleteProdotto = async (prodotto: ProdottoModel) => {
        if (prodotto.ResourceId != null)
            await this.fileService.Delete(prodotto.ResourceId)
    }

    onAddProdottoClick = () => {
        this.openModalProdotto(new ProdottoModel())
    }

    onAddProdotto = (item: ProdottoModel) => {
        let items = [...this.state.Prodotti]
        var index = items.findIndex(c => c.Id == item.Id)
        if (index > -1) {
            items[index] = item
        } else {
            items.push(item)
        }

        this.setState({ Prodotti: items })
    }

    openModalProdotto = (data: ProdottoModel) => {
        this.setState({
            selectedProdotto: data,
            visibleProdotto: true
        })
    }

    closeModalProdotto = () => {
        this.setState({
            selectedProdotto: null,
            visibleProdotto: false
        })
    }

    uploadImage = async (options: RcCustomRequestOptions) => {
        const { onSuccess, onError, file } = options

        var resp = await this.service.UploadResource(this.state.Id, file)
        if (resp.IsValid) {
            this.setState({ ResourceId: resp.Id })
            onSuccess(resp, file)
        } else {
            const error = new Error(resp.Notifies[0].Message)
            onError(error)
        }
    };

    renderProdotto = (item: ProdottoModel, _: number) => {
        return (
            <List.Item
                key={item.Nome}
                actions={[
                    <Button
                        type="link" icon={<EditOutlined />} key="list-vertical-edit-o" style={{ padding: '0' }} onClick={() => this.openModalProdotto(item)}>Modifica</Button>,
                    <Button danger type="link" icon={<DeleteOutlined />} key="list-vertical-delete-o" style={{ padding: '0' }} onClick={() => this.onRemoveProdotto(item)}>Elimina</Button>
                ]}
                extra={<TTImage resourceId={item.ResourceId} />}>
                <List.Item.Meta title={item.Nome} description={item.Link} />
            </List.Item>
        )
    }

    render() {
        return (
            <>
                <PageHeader
                    onBack={() => this.props.history.goBack()}
                    title="Crea Promozione"
                    extra={[
                        <Button key="add" type="primary" icon={<SaveOutlined />} onClick={() => this.onSalvaClick()}>Salva</Button>
                    ]}>
                    <Form>
                        <TTFormItem label="Titolo" required={true}>
                            <Input value={this.state.Titolo} onChange={e => this.setState({ Titolo: e.target.value })} />
                        </TTFormItem>
                        <TTFormItem label="Descrizione" required={true}>
                            <Input.TextArea value={this.state.Descrizione} onChange={e => this.setState({ Descrizione: e.target.value })} />
                        </TTFormItem>
                        <TTFormItem label="Link"
                            tooltip="Inserisci qui il link all'ecommerce se necessario"
                            rules={[{ type: 'url', message: 'Il valore inserito non è un url' }]}>
                            <Input value={this.state.Link} onChange={e => this.setState({ Link: e.target.value })} />
                        </TTFormItem>
                        <TTFormItem label="Destinatario">
                            <TTSelect value={this.state.Destinatario} onChange={e => this.setState({ Destinatario: e as string })}>
                                {renderSelectKeyValue(this.state.DestinatarioList)}
                            </TTSelect>
                        </TTFormItem>
                        <TTFormItem label="Immagine">
                            <TTImageUpload
                                listType="picture-card"
                                resourceId={this.state.ResourceId}
                                customRequest={options => this.uploadImage(options)} />
                        </TTFormItem>
                    </Form>
                </PageHeader>

                {this.renderClienti()}

                <PageHeader title={<Descriptions title="Prodotti" />} extra={[
                    <Button type="primary" key="add-prodotto" icon={<PlusOutlined />} onClick={() => this.onAddProdottoClick()}>Aggiungi</Button>
                ]}>
                    <List
                        dataSource={this.state.Prodotti}
                        rowKey={it => it.Nome}
                        itemLayout="vertical"
                        renderItem={(item, index) => this.renderProdotto(item, index)} />
                </PageHeader>

                { /* E' sotto if per distruggere la modale una volta ottenuti i dati */}
                {this.state.visibleProdotto ?
                    <ModalProdotto
                        visible={this.state.visibleProdotto}
                        selectedProdotto={this.state.selectedProdotto}
                        promozioneId={this.state.Id}
                        closeModal={() => this.closeModalProdotto()}
                        do={data => this.onAddProdotto(data as ProdottoModel)} /> : <></>}

                <ModalFiltroClienti
                    visible={this.state.visibleFiltroClienti}
                    do={(data: ClienteSearcher) => this.onUpdateSearcher(data)}
                    closeModal={() => this.toggleFiltroClienti(false)} />
            </>
        )
    }

    onUpdateSearcher = (data: ClienteSearcher) => {
        this.setState({ Searcher: data })
    }

    toggleFiltroClienti = (visible: boolean) => {
        this.setState({ visibleFiltroClienti: visible })
    }

    renderClienti = () => {
        if (this.state.Destinatario == PROMOZIONE_DESTINATARIO_SELEZIONE)
            return (
                <PageHeader title={<Descriptions title="Seleziona uno o più clienti" />}>
                    <PickerClienti selectedCustomers={data => this.setState({ Clienti: data })} />
                </PageHeader>
            )

        if (this.state.Destinatario == PROMOZIONE_DESTINATARIO_FILTRO)
            return (
                <PageHeader title={<Descriptions title="Filtro Clienti" />} extra={[
                    <Button type="primary" key="filtro-add" icon={<EditOutlined />} onClick={() => this.toggleFiltroClienti(true)}>Modifica</Button>
                ]}>
                    <RiepilogoFiltroClienti {...this.state.Searcher} />
                </PageHeader>
            )

        return <></>
    }
}

export const PageCreaPromozione = withRouter(PageCreaPromozioneComp)