import * as React from 'react'
import { Layout } from 'antd';

import moment from 'moment'
import 'moment/locale/it'

import './styles/custom.css'
import 'antd/dist/antd.less'
import './styles/antd-theme.less'

import { IAuthService } from './services/security/AuthService';
import SERVICE_IDENTIFIER from './wires/Identifiers';
import container from './wires/Bootstrapper';

import { AppSidebar } from './components/AppSidebar'

import { AppHeader } from './components/AppHeader';
import { AppFooter } from './components/AppFooter';
import { AppRoutes } from './components/AppRoutes';
import { AppLogin } from  './components/AppLogin';
import { AppContext, AppContextModel } from './components/AppContext';
import { IStatisticaService } from './services/StatisticaService';

import Logo from './resources/ottica-lotito-logo.jpg'

const { Content, Header } = Layout
moment.locale('it')

export class AppState extends AppContextModel {

}

export default class App extends React.Component<any, AppState> {

    authService: IAuthService
    statService: IStatisticaService

    constructor(props) {
        super(props)
        this.authService = container.get(SERVICE_IDENTIFIER.AUTH_SERVICE)
        this.statService = container.get(SERVICE_IDENTIFIER.STATISTICA_SERVICE)

        let stato: AppState = {
            Richieste: 0,
            RefreshRichieste: () => this.refreshRichiste()
        }

        this.state = stato
    }

    refreshRichiste = async () => {
        let resp = await this.statService.GetRichiesteStats()
        if (resp.IsValid)
            this.setState({ Richieste: resp.Aperte })
    }

    renderLayout = () => {
        return (
            <Layout className="full-height">
                <Header className="header" style={{ marginBottom: '2px' }}>
                    <img src={Logo} alt="logo" height="45px" />
                </Header>
                <Layout className="site-layout">
                    <Layout>
                        <Content className="site-layout-background">
                            <AppLogin />
                        </Content>
                        <AppFooter />
                    </Layout>
                </Layout>
            </Layout>
        )
    }

    renderAuthenticatedLayout = () => {
        return (
            <AppContext.Provider value={this.state}>
            <Layout className="full-height">
                <AppHeader />
                <Layout className="site-layout">
                    <AppSidebar />
                    <Layout style={{ padding: '24px 24px 0px 24px' }}>
                        <Content
                            className="site-layout-background"
                            style={{
                                background: '#fff',
                                padding: 24,
                                margin: 0,
                                minHeight: 'auto'
                            }}>
                            <AppRoutes />
                        </Content>
                        <AppFooter />
                    </Layout>
                </Layout>
                </Layout>
            </AppContext.Provider>
        )
    }

    render() {
        return this.authService.isAuthenticated()
            ? this.renderAuthenticatedLayout()
            : this.renderLayout()
    }
}