import * as React from 'react'
import container from '../../wires/Bootstrapper'
import SERVICE_IDENTIFIER from '../../wires/Identifiers'
import { IFileService } from '../../services/FileService'

interface ITTImageProps {
    onClick?: () => void
    resourceId: string
}

class TTImageState {
    ResourceId: string
}

export class TTImage extends React.Component<ITTImageProps, TTImageState> {

    fileService: IFileService

    constructor(props) {
        super(props)
        this.fileService = container.get(SERVICE_IDENTIFIER.FILE_SERVICE)
        this.state = new TTImageState()
    }

    componentDidMount() {
        if (this.props.resourceId != null)
            this.setState({ ResourceId: this.props.resourceId })
    }

    componentDidUpdate(prevProps: ITTImageProps, prevState: TTImageState) {
        if (prevProps.resourceId != this.props.resourceId)
            this.setState({ ResourceId: this.props.resourceId })
    }

    getImage = () => {
        return (
            <img alt="image"
                height={100}
                src={this.fileService.GetImageUrl(this.state.ResourceId)} />
        )
    }

    getClickableImage = () => {
        return (
            <a onClick={() => this.props.onClick()}>
                {this.getImage()}
            </a>
        )
    }

    render() {
        if (this.state.ResourceId == null)
            return <></>

        return this.props.onClick == null
            ? this.getImage()
            : this.getClickableImage()
    }
}