import { injectable } from "inversify"

import container from '../wires/Bootstrapper'
import SERVICE_IDENTIFIER from '../wires/Identifiers'

import * as Api from "../constants/ApiUrlConstants"
import { IApiService } from "./shared/ApiService"
import { IApiServiceExt } from "./shared/ApiServiceExt"
import { ClienteModel } from "../models/ClienteModels"
import { ProjectionPagedModel } from "../models/ProjectionPageModel"
import { RenderClienteSearcher, ClienteSearcher } from "../models/ClienteSearcher"
import { ModelBase } from "../models/ModelBase"

export interface IClienteService {
    Get(id: string): Promise<ClienteModel>
    RenderSearch(): Promise<RenderClienteSearcher>
    Search(searcher: ClienteSearcher): Promise<ProjectionPagedModel<ClienteModel>>
    SospendiCliente(Cliente: string): Promise<ModelBase>

}

@injectable()
export class ClienteService implements IClienteService {

    apiService: IApiService
    apiServiceExt: IApiServiceExt

    constructor() {
        this.apiService = container.get(SERVICE_IDENTIFIER.API_SERVICE)
        this.apiServiceExt = container.get(SERVICE_IDENTIFIER.API_SERVICE_EXT)
    }

    async Get(id: string): Promise<ClienteModel> {
        return this.apiService.getAsync<ClienteModel>(Api.GetCliente, { id })
    }

    async RenderSearch(): Promise<RenderClienteSearcher> {
        return this.apiService.getAsync<RenderClienteSearcher>(Api.RenderSearchCliente, {})
    }

    async Search(searcher: ClienteSearcher): Promise<ProjectionPagedModel<ClienteModel>> {
        return this.apiService.postAsync<ProjectionPagedModel<ClienteModel>>(Api.SearchCliente, searcher)
    }

    async SospendiCliente(Cliente: string): Promise<ModelBase> {
        return this.apiServiceExt.getAsync<ModelBase>(Api.SospendiCliente, { Cliente })
    }
}