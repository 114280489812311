import * as React from 'react'
import { ClienteSearcher } from '../../models/ClienteSearcher'
import { Descriptions, Typography } from 'antd'
import { mapEta, mapSesso } from '../../constants/Constants';

const { Text } = Typography;

interface RiepilogoFiltroClientiStateProps extends ClienteSearcher {}

export const RiepilogoFiltroClienti: React.FC<RiepilogoFiltroClientiStateProps> = (props) => {

    const renderWithPlaceholder = (data: React.ReactNode) => {
        if (!data)
            return <Text type="secondary">Parametro non impostato</Text>

        return data
    }

    const getEta = () => {
        let operand = mapEta[props.ComparazioneEta]
        if (!operand && !props.Eta)
            return <Text type="secondary">Parametro non impostato</Text>

        return `${operand} ${props.Eta} anni`
    }

    return (
        <Descriptions layout="horizontal" column={1}>
            <Descriptions.Item label="Età">{getEta()}</Descriptions.Item>
            <Descriptions.Item label="Sesso">{renderWithPlaceholder(mapSesso[props.Sesso])}</Descriptions.Item>
            <Descriptions.Item label="Comune">{renderWithPlaceholder(props.Comune)}</Descriptions.Item>
            <Descriptions.Item label="Provincia">{renderWithPlaceholder(props.Provincia)}</Descriptions.Item>
        </Descriptions>
    )
}