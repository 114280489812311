import * as React from 'react'
import { Tooltip } from 'antd'
import { TooltipProps } from 'antd/lib/tooltip'
import './TTTooltip.less'
import { QuestionCircleOutlined } from '@ant-design/icons'

export const TTTooltip: React.FC<TooltipProps> = (props) => {
    if (!props.title) return <span />
    return (
        <Tooltip {...props}>
            <QuestionCircleOutlined className="tt-tooltip" />
        </Tooltip>
    )
}