import { ModelBase } from "./ModelBase";

export class RichiestaBaseModel extends ModelBase
{
    Titolo: string 
    Descrizione: string
}

export class RichiestaModel extends RichiestaBaseModel
{
    Cliente: string
    ClienteId: string
    Letto: boolean
    Data: Date

    ClienteCodice: string
    ClienteEmail: string
    ClienteTelefono: string
}

export class RichiesteClienteModel extends ModelBase {
    Create: number
}