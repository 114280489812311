import { injectable } from "inversify";

import container from '../wires/Bootstrapper'
import SERVICE_IDENTIFIER from '../wires/Identifiers'

import * as Api from "../constants/ApiUrlConstants";
import { IApiService } from "./shared/ApiService";
import { IApiServiceExt } from "./shared/ApiServiceExt";

import { ComunicazioneModel, CreaComunicazioneModel, ComunicazioneBaseModel, ComunicazioneClienteModel } from "../models/ComunicazioniModels";
import { ProjectionPagedModel } from "../models/ProjectionPageModel";
import { SearcherBaseModel } from "../models/SearcherBaseModel";
import { ModelBase } from "../models/ModelBase";
import { IdSearcher } from "../models/IdSearcher";

export interface IComunicazioneService {
    Get(id: string): Promise<ComunicazioneModel>
    Search(searcher: SearcherBaseModel): Promise<ProjectionPagedModel<ComunicazioneModel>>
    SearchByCliente(searcher: IdSearcher): Promise<ProjectionPagedModel<ComunicazioneClienteModel>>
    RenderCrea(): Promise<CreaComunicazioneModel>
    Crea(data: CreaComunicazioneModel): Promise<ModelBase>
}

@injectable()
export class ComunicazioneService implements IComunicazioneService {

    apiService: IApiService;
    apiServiceExt: IApiServiceExt;

    constructor() {
        this.apiService = container.get(SERVICE_IDENTIFIER.API_SERVICE);
        this.apiServiceExt = container.get(SERVICE_IDENTIFIER.API_SERVICE_EXT);
    }

    async Get(id: string): Promise<ComunicazioneModel> {
        return this.apiService.getAsync<ComunicazioneModel>(Api.GetComunicazione, { id })
    }

    async Search(searcher: SearcherBaseModel): Promise<ProjectionPagedModel<ComunicazioneModel>> {
        return this.apiService.postAsync<ProjectionPagedModel<ComunicazioneModel>>(Api.SearchComunicazione, searcher)
    }

    async SearchByCliente(searcher: IdSearcher): Promise<ProjectionPagedModel<ComunicazioneClienteModel>> {
        return this.apiService.postAsync<ProjectionPagedModel<ComunicazioneClienteModel>>(Api.SearchComunicazioneByCliente, searcher)
    }

    async RenderCrea(): Promise<CreaComunicazioneModel> {
        return this.apiService.getAsync<CreaComunicazioneModel>(Api.RenderCreaComunicazione, {})
    }

    async Crea(data: CreaComunicazioneModel): Promise<ModelBase> {
        return this.apiServiceExt.postAsync<ModelBase>(Api.CreaComunicazione, data)
    }

    async Modifica(data: ComunicazioneBaseModel): Promise<ModelBase> {
        return this.apiServiceExt.postAsync<ModelBase>(Api.CreaComunicazione, data)
    }
}