import * as React from 'react'
import { PageHeader, Descriptions, Button } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import { RouteComponentProps, withRouter } from 'react-router'
import moment from 'moment'

import container from '../../wires/Bootstrapper'
import SERVICE_IDENTIFIER from '../../wires/Identifiers'
import { IRichiestaService } from '../../services/RichiestaService'

import { RichiestaRouteParams } from './RichiestaRouteParams'
import { RichiestaModel } from '../../models/RichiesteModels'

import { AppContext } from '../AppContext'

class PageRichiestaState extends RichiestaModel {

}

export class PageRichiestaComp extends React.Component<RouteComponentProps<RichiestaRouteParams>, PageRichiestaState> {

    service: IRichiestaService
    context!: React.ContextType<typeof AppContext>

    constructor(props) {
        super(props)

        this.state = new PageRichiestaState()
        this.service = container.get(SERVICE_IDENTIFIER.RICHIESTA_SERVICE)
    }

    componentDidMount() {
        this.load()
    }

    load = async () => {
        let id = this.props.match.params.id
        let resp = await this.service.Get(id)
        if (resp.IsValid)
            this.setState(resp)
    }

    onMarkAsRead = async () => {
        let id = this.props.match.params.id
        let resp = await this.service.Close(id)
        if (resp.IsValid) {
            this.context.RefreshRichieste()
            this.load()
        }
    }

    render() {
        return (
            <>
                <PageHeader
                    onBack={() => this.props.history.goBack()}
                    title={this.state.Titolo}
                    extra={[
                        <Button key="close" type="primary" icon={<CheckOutlined />} onClick={() => this.onMarkAsRead()} disabled={this.state.Letto}>Segna come letta</Button>
                    ]}>
                    <>
                        {this.state.Descrizione}
                        <br />
                        <br />
                        <Descriptions>
                            <Descriptions.Item label="Creata da">{this.state.Cliente}</Descriptions.Item>
                            <Descriptions.Item label="Creata il">{moment(this.state.Data).format('LLL')}</Descriptions.Item>
                        </Descriptions>
                        <br />
                        <Descriptions title="Contatti cliente">
                            <Descriptions.Item label="Email">
                                <a target="_blank" href={`mailto:${this.state.ClienteEmail}`}>{this.state.ClienteEmail}</a>
                            </Descriptions.Item>
                            <Descriptions.Item label="Telefono Cellulare">
                                <a target="_blank" href={`tel:${this.state.ClienteTelefono}`}>{this.state.ClienteTelefono}</a>
                            </Descriptions.Item>
                            <Descriptions.Item label="Codice Cliente">
                                {this.state.ClienteCodice}
                            </Descriptions.Item>
                        </Descriptions>
                    </>
                </PageHeader>
            </>
        )
    }
}

PageRichiestaComp.contextType = AppContext

export const PageRichiesta = withRouter(PageRichiestaComp)