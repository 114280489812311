import * as React from 'react'
import { Badge } from 'antd'
import { AppContext } from '../AppContext'
import { IStatisticaService } from '../../services/StatisticaService'
import { Richiesta } from './TTIcons'

export class RichiesteSidebarIcon extends React.Component<any, any> {

    service: IStatisticaService
    context!: React.ContextType<typeof AppContext>

    constructor(props) {
        super(props)
    }

    renderBadge = (value: number) => {
        let icon = <Richiesta />
        let comp = <Badge count={value} color="#fa505d" offset={[-10, 13]}>{icon}</Badge>

        return value > 0 ? comp : icon
    }

    render() {
        return (
            <AppContext.Consumer>
                { value => this.renderBadge(value.Richieste) }
            </AppContext.Consumer>
        )
    }
}

RichiesteSidebarIcon.contextType = AppContext
