import * as React from 'react'
import { Form, Space } from 'antd'
//import { TTTooltip } from './TTTooltip'
import { FormItemProps } from 'antd/lib/form'
import { TTTooltip } from './TTTooltip'

export interface ITTFormItem {
    tooltip?: string
}


const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
    }
}

/// Form.Item con gestione tooltip e dimensioni di default
export const TTFormItem: React.FC<FormItemProps & ITTFormItem> = (props) => {

    let title
    if (props.tooltip != null) {
        title = <Space>
            {props.label}
            <TTTooltip title={props.tooltip} />
        </Space>
    } else {
        title = props.label
    }

    return (
        <Form.Item {...formItemLayout} {...props} label={title} />
    )
}