import { injectable } from "inversify";

import container from '../wires/Bootstrapper'
import SERVICE_IDENTIFIER from '../wires/Identifiers'

import * as Api from "../constants/ApiUrlConstants"
import { IApiService } from "./shared/ApiService"

import { IdSearcher } from "../models/IdSearcher";
import { StatLinkClienteModel, StatLetturaModel } from "../models/StatModels"
import { ProjectionPagedModel } from "../models/ProjectionPageModel";

export interface IStatPromozioneService {
    SearchClientiStats(data: IdSearcher): Promise<ProjectionPagedModel<StatLinkClienteModel>>
    GetRead(ClienteId: string): Promise<StatLetturaModel>
    GetAllRead(): Promise<StatLetturaModel>
}

@injectable()
export class StatPromozioneService implements IStatPromozioneService {

    apiService: IApiService;

    constructor() {
        this.apiService = container.get(SERVICE_IDENTIFIER.API_SERVICE);
    }

    async SearchClientiStats(data: IdSearcher): Promise<ProjectionPagedModel<StatLinkClienteModel>> {
        return this.apiService.postAsync<ProjectionPagedModel<StatLinkClienteModel>>(Api.SearchStatPromozione, data)
    }

    async GetRead(ClienteId: string): Promise<StatLetturaModel> {
        return this.apiService.getAsync<StatLetturaModel>(Api.GetReadStatPromozione, { ClienteId })
    }

    async GetAllRead(): Promise<StatLetturaModel> {
        return this.apiService.getAsync<StatLetturaModel>(Api.GetAllReadStatPromozione, { })
    }
}