import * as React from 'react'
import { List, Badge } from 'antd'
import moment from 'moment'
import { withRouter, RouteComponentProps } from 'react-router'

import { PagedBaseState } from '../../models/PagedBaseState'
import { RichiestaModel } from '../../models/RichiesteModels'
import { IdSearcher } from '../../models/IdSearcher'

import SERVICE_IDENTIFIER from '../../wires/Identifiers'
import container from '../../wires/Bootstrapper'

import { IRichiestaService } from '../../services/RichiestaService'
import { ClienteRouteParams } from './ClienteRouteParams'

import { TTAvatar } from '../TTAvatar'
import { Richiesta } from '../tools/TTIcons'
import { AvatarRichieste } from '../richieste/AvatarRichieste'

class TabRichiesteState extends PagedBaseState<RichiestaModel>{

}

export class TabRichiesteComp extends React.Component<RouteComponentProps<ClienteRouteParams>, TabRichiesteState> {

    service: IRichiestaService

    constructor(props) {
        super(props)

        this.service = container.get(SERVICE_IDENTIFIER.RICHIESTA_SERVICE)
        this.state = new TabRichiesteState()
    }

    componentDidMount() {
        this.onSearch(1)
    }

    onSearch = async (page: number) => {
        let id = this.props.match.params.id
        let model: IdSearcher = {
            Id: id,
            Righe: 10,
            Pagina: page
        }
        let resp = await this.service.SearchByCliente(model)
        this.setState({
            Data: resp,
            Page: page
        })
    }

    onRichiestaClick = id => {
        this.props.history.push(`/Richiesta/${id}`)
    }

    renderAvatar = (item: RichiestaModel) => {
        let avatar = <TTAvatar icon={<Richiesta />} />
        let avatarWithBadge = <Badge dot color="#fa505d" offset={[-4, 4]} style={{ width: '8px', height: '8px' }}>{avatar}</Badge>

        return item.Letto == false ? avatarWithBadge : avatar
    }

    render() {
        return (
            <List
                dataSource={this.state.Data.Data}
                rowKey={it => it.Id}
                pagination={{
                    onChange: (page) => this.onSearch(page),
                    pageSize: 10,
                    total: this.state.Data.Total,
                    current: this.state.Page
                }}
                renderItem={item => (
                    <List.Item key={item.Id}>
                        <List.Item.Meta
                            avatar={<AvatarRichieste letto={item.Letto} />}
                            title={<a onClick={() => this.onRichiestaClick(item.Id)}>{item.Titolo}</a>}
                            description={moment(item.Data).format('LLL')} />
                    </List.Item>
                )}>
            </List>
        )
    }
}

export const TabRichieste = withRouter(TabRichiesteComp)