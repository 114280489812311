export class Notify {
    Message: string;
    ServerMessage: string;
}

export class ModelBase {
    public Notifies: Array<Notify>;
    public IsValid: boolean;
    public Code: string;
    public Id: string;
}

export class KeyValueModel {
    Key: string
    Value: string
}
