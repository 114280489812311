import * as React from 'react'
import { PageHeader, Descriptions, Tooltip } from 'antd'

import { ProdottoModel } from '../../models/ProdottoModel'
import { TTImageModal } from '../tools/TTImageModal'
import { TableStatsProdotto } from './TableStatsProdotto'
import { IFileService } from '../../services/FileService'
import SERVICE_IDENTIFIER from '../../wires/Identifiers'
import container from '../../wires/Bootstrapper'
import { TTImage } from '../tools/TTImage'
import { TTContent } from '../tools/TTContent'

interface SezioneProdottoProps {
    prodotto: ProdottoModel
}

class SezioneProdottoState extends ProdottoModel {
    visibleImageModal: boolean = false
}

export class SezioneProdotto extends React.Component<SezioneProdottoProps, SezioneProdottoState> {
    fileService: IFileService

    constructor(props) {
        super(props)

        // Faccio così per evitare di dover usare componentDidUpdate per leggere l'id prodotto corretto
        // nel componente di statistiche ed evitando di conseguenza una chiamata api inutile
        let state = { ...this.props.prodotto } as SezioneProdottoState
        state.visibleImageModal = false

        this.state = state

        this.fileService = container.get(SERVICE_IDENTIFIER.FILE_SERVICE)
    }

    toggleImageModal = (visible: boolean) => {
        this.setState({ visibleImageModal: visible })
    }

    getImageDescription = () => {
        if (this.state.ResourceId == null) return <></>

        return (
            <Descriptions.Item label="Immagine">
                <a onClick={() => this.toggleImageModal(true)}>Visualizza</a>
            </Descriptions.Item>
        )
    }

    render() {
        return (
            <PageHeader
                title={this.state.Nome}>
                <TTContent extra={
                    <TTImage resourceId={this.state.ResourceId} onClick={() => this.toggleImageModal(true)} />
                }>
                    <Descriptions>
                        <Descriptions.Item label="Link">
                            <Tooltip title={this.state.Link}>
                                <a onClick={() => window.open(this.state.Link)}>Vai all'ecommerce</a>
                            </Tooltip>
                        </Descriptions.Item>
                    </Descriptions>
                </TTContent>

                <Descriptions title="Statistiche" />
                <TableStatsProdotto prodottoId={this.state.Id} />

                <TTImageModal
                    visible={this.state.visibleImageModal}
                    closeModal={() => this.toggleImageModal(false)}
                    resourceId={this.state.ResourceId} />
            </PageHeader>
        )
    }
}