import * as React from 'react'
import { Badge } from 'antd'

import { TTAvatar } from '../TTAvatar'
import { Richiesta } from '../tools/TTIcons'

interface AvatarRichiesteProps {
    letto: boolean
}

export const AvatarRichieste: React.FC<AvatarRichiesteProps> = (props) => {
    let avatar = <TTAvatar icon={<Richiesta />} />
    let avatarWithBadge = <Badge dot color="#fa505d" offset={[-4, 4]} style={{ width: '8px', height: '8px' }}>{avatar}</Badge>

    return props.letto === false ? avatarWithBadge : avatar
}