import * as React from 'react'
import { Upload } from 'antd'
import { UploadChangeParam, UploadProps } from 'antd/lib/upload';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { IFileService } from '../services/FileService';

import SERVICE_IDENTIFIER from '../wires/Identifiers';
import container from '../wires/Bootstrapper';

interface TTImageUploadProps extends UploadProps {
    resourceId: string
}

class TTUploadState {
    Loading: boolean = false
    ResourceId: string
}

export class TTImageUpload extends React.Component<TTImageUploadProps, TTUploadState> {

    fileService: IFileService

    constructor(props) {
        super(props)
        this.state = new TTUploadState()
        this.fileService = container.get(SERVICE_IDENTIFIER.FILE_SERVICE)
    }

    static defaultProps = {
        resourceId: null
    }

    componentDidMount() {
        if (this.props.resourceId != null)
            this.setState({ ResourceId: this.props.resourceId })
    }

    componentDidUpdate(prevProps: TTImageUploadProps, prevState: TTUploadState) {
        if (prevProps.resourceId != this.props.resourceId)
            this.setState({ ResourceId: this.props.resourceId })
    }

    handleChange = (info: UploadChangeParam) => {
        if (this.props.onChange != null)
            this.props.onChange(info)

        if (info.file.status === 'uploading') {
            this.setState({ Loading: true })
        }
        if (info.file.status === 'done') {
            this.setState({ Loading: false })
        }
    };

    render() {
        const { onChange, ...props } = this.props
        return (
            <Upload
                accept="image/*"
                showUploadList={false}
                onChange={this.handleChange}
                disabled={this.state.ResourceId != null}
                {...props}>
                {this.state.ResourceId ?
                    <img src={this.fileService.GetImageUrl(this.state.ResourceId)} style={{ width: '100%' }} /> :
                    this.state.Loading ? <LoadingOutlined /> : <PlusOutlined />}
            </Upload>
        )
    }
}