import * as React from 'react'
import { List } from 'antd'
import moment from 'moment'
import { RouteComponentProps, withRouter } from 'react-router'

import SERVICE_IDENTIFIER from '../../wires/Identifiers'
import container from '../../wires/Bootstrapper'

import { PagedBaseState } from '../../models/PagedBaseState'
import { IdSearcher } from '../../models/IdSearcher'
import { ComunicazioneClienteModel } from '../../models/ComunicazioniModels'

import { IComunicazioneService } from '../../services/ComunicazioneService'
import { ClienteRouteParams } from './ClienteRouteParams'

import { TTAvatar } from '../TTAvatar'
import { Comunicazione } from '../tools/TTIcons'
import { TwoStateTag } from '../tools/TwoStateTag'

class TabComunicazioniState extends PagedBaseState<ComunicazioneClienteModel> {

}

export class TabComunicazioniComp extends React.Component<RouteComponentProps<ClienteRouteParams>, TabComunicazioniState> {

    service: IComunicazioneService

    constructor(props) {
        super(props)

        this.state = new TabComunicazioniState()
        this.service = container.get(SERVICE_IDENTIFIER.COMUNICAZIONE_SERVICE)
    }

    componentDidMount() {
        this.onSearch(1)
    }

    onSearch = async (page: number) => {
        let id = this.props.match.params.id
        let model: IdSearcher = {
            Id: id,
            Righe: 10,
            Pagina: page
        }
        let resp = await this.service.SearchByCliente(model)
        this.setState({
            Data: resp,
            Page: page
        })
    }

    onComunicazioneClick = id => {
        this.props.history.push(`/Comunicazione/${id}`)
    }

    render() {
        return (
            <List
                dataSource={this.state.Data.Data}
                rowKey={it => it.Id}
                pagination={{
                    onChange: (page) => this.onSearch(page),
                    pageSize: 10,
                    total: this.state.Data.Total,
                    current: this.state.Page
                }}
                renderItem={item => (
                    <List.Item key={item.Id} actions={[<TwoStateTag key="tag" value={item.Letto} on="LETTA" off="NON LETTA" />]}>
                        <List.Item.Meta
                            avatar={<TTAvatar icon={<Comunicazione />} />}
                            title={<a onClick={() => this.onComunicazioneClick(item.Id)}>{item.Titolo}</a>}
                            description={moment(item.Data).format('LLL')} />
                    </List.Item>
                )}>
            </List>
        )
    }
}

export const TabComunicazioni = withRouter(TabComunicazioniComp)
