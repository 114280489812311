import { injectable } from "inversify"
import { IApiService } from "./shared/ApiService"

import * as Api from "../constants/ApiUrlConstants"
import { RichiesteStats, ClientiStats, ClientiModel } from "../models/StatisticaModels"

import SERVICE_IDENTIFIER from "../wires/Identifiers"
import container from "../wires/Bootstrapper"
import { StatLetturaModel } from "../models/StatModels"

export interface IStatisticaService {
    GetRichiesteStats(): Promise<RichiesteStats>
    GetClientiStats(): Promise<ClientiStats>
    GetClientiCompleanno(): Promise<ClientiModel>
    GetClicks(): Promise<StatLetturaModel>
}

@injectable()
export class StatisticaService implements IStatisticaService {

    apiService: IApiService

    constructor() {
        this.apiService = container.get(SERVICE_IDENTIFIER.API_SERVICE)
    }

    async GetRichiesteStats(): Promise<RichiesteStats> {
        return this.apiService.getAsync(Api.GetRichiesteStats, null)
    }

    async GetClientiStats(): Promise<ClientiStats> {
        return this.apiService.getAsync(Api.GetClientiStats, null)
    }

    async GetClientiCompleanno(): Promise<ClientiModel> {
        return this.apiService.getAsync(Api.GetClientiCompleanno, null)
    }

    async GetClicks(): Promise<StatLetturaModel> {
        return this.apiService.getAsync(Api.GetClicks, null)
    }
}