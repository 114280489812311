import { injectable } from "inversify";

import container from '../wires/Bootstrapper'
import SERVICE_IDENTIFIER from '../wires/Identifiers'

import * as Api from "../constants/ApiUrlConstants"
import { IApiService } from "./shared/ApiService"

import { IdSearcher } from "../models/IdSearcher"
import { StatLinkClienteModel } from "../models/StatModels"
import { ProjectionPagedModel } from "../models/ProjectionPageModel"

export interface IStatProdottoService {
    SearchClientiStats(data: IdSearcher): Promise<ProjectionPagedModel<StatLinkClienteModel>>
}

@injectable()
export class StatProdottoService implements IStatProdottoService {

    apiService: IApiService

    constructor() {
        this.apiService = container.get(SERVICE_IDENTIFIER.API_SERVICE)
    }

    async SearchClientiStats(data: IdSearcher): Promise<ProjectionPagedModel<StatLinkClienteModel>> {
        return this.apiService.postAsync<ProjectionPagedModel<StatLinkClienteModel>>(Api.SearchStatProdotto, data)
    }
}

