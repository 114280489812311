import { ModelBase } from "./ModelBase"

export class ClienteModel extends ModelBase
{
    CodiceCliente: string
    Nome: string
    Cognome: string
    TelefonoCellulare: string
    Email: string
    Comune: string
    Provincia: string
    Sesso: string
    DataNascita: Date
    Eta: number
}