import * as React from 'react'
import { Menu } from 'antd'
import { Link } from 'react-router-dom'
import { MenuItemProps } from 'antd/lib/menu/MenuItem'

interface TTSiderMenuItem {
    path: string
}

export const TTSiderMenuItem: React.FC<MenuItemProps & TTSiderMenuItem> = (props) => {
    const { path, children, ...others } = props
    return (
        <Menu.Item {...others}>
            <Link to={path}>{children}</Link>
        </Menu.Item>
    )
}