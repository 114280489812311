import * as React from 'react'
import { Layout, Space } from 'antd'
import Icon from '@ant-design/icons'
import * as Icons from './../constants/Icons'

const { Footer } = Layout

export const AppFooter: React.FC<any> = (props) => {
    return (
        <Footer style={{ textAlign: 'center' }}>
            <Space>
                <span>Powered by Tootech</span>
                <Icon component={Icons['tt'] as any} />
            </Space>
        </Footer>
    )
}