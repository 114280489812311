import { ModelBase } from "./ModelBase"
import { ClienteModel } from "./ClienteModels"

export class ClientiModel extends ModelBase
{
    Clienti: Array<ClienteModel>
}

export class ClientiStats extends ModelBase
{
    Registrati: number
    CompionoAnni: number
}

export class RichiesteStats extends ModelBase
{
    Aperte: number
}
