const SERVICE_IDENTIFIER = {
    API_SERVICE: Symbol.for("ApiService"),
    API_SERVICE_EXT: Symbol.for("ApiServiceExt"),
    AUTH_SERVICE: Symbol.for("AuthService"),
    NOTIFY: Symbol.for("Notify"),

    ACCOUNT_SERVICE: Symbol.for("AccountService"),
    FILE_SERVICE: Symbol.for("FileService"),
    CLIENTE_SERVICE: Symbol.for("ClienteService"),
    COMUNICAZIONE_SERVICE: Symbol.for("ComunicazioneService"),
    STAT_COMUNICAZIONE_SERVICE: Symbol.for("StatComunicazioneService"),
    PROMOZIONE_SERVICE: Symbol.for("PromozioneService"),
    STAT_PROMOZIONE_SERVICE: Symbol.for("StatPromozioneService"),
    STAT_PRODOTTO_SERVICE: Symbol.for("StatProdottoService"),
    RICHIESTA_SERVICE: Symbol.for("RichiestaService"),
    STATISTICA_SERVICE: Symbol.for("StatisticaService")
}

export default SERVICE_IDENTIFIER