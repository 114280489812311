import * as React from 'react'
import { Table } from 'antd'

import SERVICE_IDENTIFIER from '../../wires/Identifiers'
import container from '../../wires/Bootstrapper'

import { IStatComunicazioneService } from '../../services/StatComunicazioneService'
import { PagedBaseState } from '../../models/PagedBaseState'
import { IdSearcher } from '../../models/IdSearcher'
import { TwoStateTag } from '../tools/TwoStateTag'
import { StatClienteModel } from '../../models/StatModels'

class TabellaLetturaComunicazioneState extends PagedBaseState<StatClienteModel> {
    loading: boolean = false
}

interface TableLetturaComunicazioneProps {
    comunicazioneId: string
    pageSize?: number
}

export class TableLetturaComunicazione extends React.Component<TableLetturaComunicazioneProps, TabellaLetturaComunicazioneState> {

    service: IStatComunicazioneService

    constructor(props) {
        super(props)

        this.service = container.get(SERVICE_IDENTIFIER.STAT_COMUNICAZIONE_SERVICE)
        this.state = new TabellaLetturaComunicazioneState()
    }

    static defaultProps = {
        pageSize: 10
    }

    componentDidMount() {
        this.onSearch(1)
    }

    onSearch = async (page: number) => {
        this.setState({ loading: true })

        let model: IdSearcher = {
            Id: this.props.comunicazioneId,
            Pagina: page,
            Righe: 10
        }
        let resp = await this.service.SearchLetture(model)
        this.setState({
            Data: resp,
            Page: page,
            loading: false
        })
    }

    render() {
        return (
            <Table
                dataSource={this.state.Data.Data}
                rowKey={it => it.Id}
                loading={this.state.loading}
                pagination={{
                    onChange: (page) => this.onSearch(page),
                    pageSize: this.props.pageSize,
                    total: this.state.Data.Total,
                    current: this.state.Page
                }}>
                <Table.Column dataIndex="Cliente" title="Cliente" />
                <Table.Column dataIndex="Letto" title="Stato comunicazione" render={value =>
                    <TwoStateTag value={value} on="LETTA" off="NON LETTA" />} />
            </Table>
        )
    }
}