import * as React from 'react'
import { PageHeader, Button, Row, Col, Input, List } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { RouteComponentProps, withRouter } from 'react-router'
import moment from 'moment'

import { TTAvatar } from '../TTAvatar'
import { SearcherBaseModel } from '../../models/SearcherBaseModel'
import { IPromozioneService } from '../../services/PromozioneService'
import SERVICE_IDENTIFIER from '../../wires/Identifiers'
import container from '../../wires/Bootstrapper'
import { PagedBaseState } from '../../models/PagedBaseState'
import { PromozioneBaseModel } from '../../models/PromozioniModels'
import { Promozione } from '../tools/TTIcons'

class PagePromozioniState extends PagedBaseState<PromozioneBaseModel> {

}

export class PagePromozioniComp extends React.Component<RouteComponentProps, PagePromozioniState> {

    service: IPromozioneService

    constructor(props) {
        super(props)

        this.service = container.get(SERVICE_IDENTIFIER.PROMOZIONE_SERVICE)
        this.state = new PagePromozioniState()
    }

    componentDidMount() {
        this.onSearch(1)
    }

    onSearch = async (page: number) => {
        let model: SearcherBaseModel = {
            Righe: 10,
            Pagina: page,
            FullText: this.state.Searcher
        }
        let resp = await this.service.Search(model)
        this.setState({
            Data: resp,
            Page: page
        })
    }

    onPromozioneClick = id => {
        this.props.history.push(`Promozione/${id}`)
    }

    onCreaClick = () => {
        this.props.history.push(`Promozione`)
    }

    render() {
        return (
            <PageHeader title="Promozioni" extra={[
                <Button key="add" type="primary" icon={<PlusOutlined />} onClick={() => this.onCreaClick()}>Crea</Button>
            ]}>
                <br />
                <Row>
                    <Col offset={1} span={22} >
                        <Input.Search
                            placeholder="Cerca promozione"
                            onChange={e => this.setState({ Searcher: e.target.value })}
                            onSearch={() => this.onSearch(1)}
                            onPressEnter={() => this.onSearch(1)}
                            value={this.state.Searcher}
                            enterButton />
                    </Col>
                </Row>
                <br />
                <List
                    dataSource={this.state.Data.Data}
                    rowKey="Id"
                    pagination={{
                        onChange: (page) => this.onSearch(page),
                        pageSize: 10,
                        total: this.state.Data.Total,
                        current: this.state.Page
                    }}
                    renderItem={item => (
                        <List.Item key={item.Id}>
                            <List.Item.Meta
                                avatar={<TTAvatar icon={<Promozione />} />}
                                title={item.IsActive
                                    ? <a onClick={() => this.onPromozioneClick(item.Id)}>{item.Titolo}</a>
                                    : <span style={{ color: "#DFDFDF", cursor:"pointer"}}
                                            onClick={() => this.onPromozioneClick(item.Id)}>{item.Titolo}</span>}
                                description={moment(item.DataCreazione).format('LLL')}
                            />
                        </List.Item>
                    )}>
                </List>
            </PageHeader>
        )
    }
}

export const PagePromozioni = withRouter(PagePromozioniComp)