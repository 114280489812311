import * as React from 'react'
import { PageHeader, Descriptions, Tooltip, Button } from 'antd'
import moment from 'moment'

import { IPromozioneService } from '../../services/PromozioneService'

import SERVICE_IDENTIFIER from '../../wires/Identifiers'
import container from '../../wires/Bootstrapper'
import { PromozioneModel } from '../../models/PromozioniModels'
import { RouteComponentProps, withRouter } from 'react-router'
import { PromozioneRouteParams } from './PromozioneRouteParams'
import { TTImageModal } from '../tools/TTImageModal'
import { TableStatsPromozione } from './TableStatsPromozione'
import { SezioneProdotto } from './SezioneProdotto'
import { TTImage } from '../tools/TTImage'
import { TTContent } from '../tools/TTContent'

class PagePromozioneState extends PromozioneModel {
    visibleImageModal: boolean = false
}


export class PagePromozioneComp extends React.Component<RouteComponentProps<PromozioneRouteParams>, PagePromozioneState> {

    service: IPromozioneService

    constructor(props) {
        super(props)

        this.service = container.get(SERVICE_IDENTIFIER.PROMOZIONE_SERVICE)
        this.state = new PagePromozioneState()
    }

    componentDidMount() {
        this.load()
    }

    load = async () => {
        let id = this.props.match.params.id
        let resp = await this.service.Get(id)
        if (resp.IsValid)
            this.setState(resp)
    }

    toggleImageModal = (visible: boolean) => {
        this.setState({ visibleImageModal: visible })
    }

    getLinkDescription = () => {
        if (this.state.Link == null) return <></>

        return (
            <Descriptions.Item label="Link">
                <Tooltip title={this.state.Link}>
                    <a onClick={() => window.open(this.state.Link)}>Vai all'ecommerce</a>
                </Tooltip>
            </Descriptions.Item>
        )
    }

    closePromozione = async () => {
        let id = this.props.match.params.id
        let resp = await this.service.Close(id)
        if (resp.IsValid)
            this.setState(resp)
    }

    render() {
        let prodotti: JSX.Element[] = []
        if (this.state.Prodotti)
            prodotti = this.state.Prodotti.map(it => <SezioneProdotto prodotto={it} key={it.Id} />)

        return (
            <>
                <PageHeader
                    onBack={() => this.props.history.goBack()}
                    title={this.state.Titolo}
                    extra={[
                        <Button key="1" type="primary"  disabled={!this.state.IsActive}
                            onClick={()=> this.closePromozione() }>Termina Promozione</Button>
                    ]}>
                    <TTContent extra={
                        <TTImage resourceId={this.state.ResourceId} onClick={() => this.toggleImageModal(true)} />
                    }>
                        <p>{this.state.Descrizione}</p>

                        <Descriptions>
                            <Descriptions.Item label="Creata il">
                                {moment(this.state.DataCreazione).format('LLL')}
                            </Descriptions.Item>
                            {this.state.DataValidita != null
                                ? <Descriptions.Item  label="Terminata il">
                                    <span style={{ color: '#FF0000' }}> {moment(this.state.DataValidita).format('LLL')}</span>
                                </Descriptions.Item>
                             :<></>
                             }
                            {this.getLinkDescription()}
                        </Descriptions>

                    </TTContent>

                    <Descriptions title="Visualizzazioni" />
                    <TableStatsPromozione promozioneId={this.props.match.params.id} showRedirect={this.state.Link != null} />

                    <TTImageModal
                        visible={this.state.visibleImageModal}
                        closeModal={() => this.toggleImageModal(false)}
                        resourceId={this.state.ResourceId} />
                </PageHeader>

                {prodotti}
            </>
        )
    }
}

export const PagePromozione = withRouter(PagePromozioneComp)