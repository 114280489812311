import * as React from 'react'
import { Layout, Menu, Row, Col } from 'antd'
import { UserOutlined, KeyOutlined } from '@ant-design/icons'

import container from './../wires/Bootstrapper'
import SERVICE_IDENTIFIER from './../wires/Identifiers'
import { IAuthService } from '../services/security/AuthService'

import Logo from '../resources/ottica-lotito-logo.jpg'

const { Header } = Layout

export class AppHeader extends React.Component<any, any> {

    authService: IAuthService

    constructor(props) {
        super(props)
        this.authService = container.get(SERVICE_IDENTIFIER.AUTH_SERVICE)
    }

    logOut = () => {
        this.authService.signOutAndReload()
    }

    // marginBottom = 2px è per far si che l'elevation dell'header sia visibile altrimenti header e pagina sarebbero sullo stesso livello.
    // E' un bug potrebbe essere sistemato nelle prossime versioni di ant
    render() {
        return (
            <Header className="header" style={{ marginBottom: '2px' }}>
                <Row justify="space-between">
                    <Col>
                        <img src={Logo} alt="logo" height="45px" />
                    </Col>
                    <Col>
                        <Menu mode="horizontal">
                            <Menu.SubMenu title={<UserOutlined />}>
                                <Menu.Item key="log" icon={<KeyOutlined />} onClick={() => this.logOut()}>Esci</Menu.Item>
                            </Menu.SubMenu>
                        </Menu>
                        </Col>
                </Row>
            </Header>
        )
    }
}