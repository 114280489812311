import * as React from 'react'
import { Route } from 'react-router';

import { PageClienti } from './clienti/PageClienti'
import { PageCliente } from './clienti/PageCliente'
import { PageComunicazioni } from './comunicazioni/PageComunicazioni'
import { PageCreaComunicazione } from './comunicazioni/PageCreaComunicazione'
import { PageComunicazione } from './comunicazioni/PageComunicazione'
import { PagePromozioni } from './promozioni/PagePromozioni'
import { PageCreaPromozione } from './promozioni/PageCreaPromozione'
import { PagePromozione } from './promozioni/PagePromozione';
import { PageRichieste } from './richieste/PageRichieste';
import { PageRichiesta } from './richieste/PageRichiesta';
import { PageStatistica } from './statistiche/PageStatistica';

interface AppRoutesProps {
    authenticated?: boolean
}

export const AppRoutes: React.FC<AppRoutesProps> = (props) => {
    return <>
        <Route path="/" exact component={PageStatistica} />

        <Route path="/Clienti" component={PageClienti} />
        <Route path="/Cliente/:id" component={PageCliente} />

        <Route path="/Comunicazioni" component={PageComunicazioni} />
        <Route exact path="/Comunicazione" component={PageCreaComunicazione} />
        <Route exact path="/Comunicazione/:id" component={PageComunicazione} />

        <Route path="/Promozioni" component={PagePromozioni} />
        <Route exact path="/Promozione" component={PageCreaPromozione} />
        <Route exact path="/Promozione/:id" component={PagePromozione} />

        <Route path="/Richieste" component={PageRichieste} />
        <Route exact path="/Richiesta/:id" component={PageRichiesta} />
    </>
}