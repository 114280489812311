import { injectable } from "inversify"
import container from '../wires/Bootstrapper'
import SERVICE_IDENTIFIER from '../wires/Identifiers'

import * as Api from "../constants/ApiUrlConstants"
import { IApiService } from "./shared/ApiService"
import { IApiServiceExt } from "./shared/ApiServiceExt"

import { RichiestaModel, RichiesteClienteModel } from "../models/RichiesteModels";
import { SearcherBaseModel } from "../models/SearcherBaseModel";
import { ProjectionPagedModel } from "../models/ProjectionPageModel";
import { IdSearcher } from "../models/IdSearcher"

export interface IRichiestaService {
    Get(Id: string): Promise<RichiestaModel>
    Search(searcher: SearcherBaseModel): Promise<ProjectionPagedModel<RichiestaModel>>
    SearchByCliente(searcher: IdSearcher): Promise<ProjectionPagedModel<RichiestaModel>>
    Count(ClienteId: string): Promise<RichiesteClienteModel>
    Close(Id: string): Promise<RichiesteClienteModel>
}

@injectable()
export class RichiestaService implements IRichiestaService {

    apiService: IApiService
    apiServiceExt: IApiServiceExt

    constructor() {
        this.apiService = container.get(SERVICE_IDENTIFIER.API_SERVICE)
        this.apiServiceExt = container.get(SERVICE_IDENTIFIER.API_SERVICE_EXT)
    }

    async Get(Id: string): Promise<RichiestaModel> {
        return await this.apiService.getAsync<RichiestaModel>(Api.GetRichiesta, { Id })
    }

    async Search(searcher: SearcherBaseModel): Promise<ProjectionPagedModel<RichiestaModel>> {
        return await this.apiService.postAsync<ProjectionPagedModel<RichiestaModel>>(Api.SearchRichiesta, searcher)
    }

    async SearchByCliente(searcher: IdSearcher): Promise<ProjectionPagedModel<RichiestaModel>> {
        return this.apiService.postAsync<ProjectionPagedModel<RichiestaModel>>(Api.SearchRichiestaByCliente, searcher)
    }

    async Count(ClienteId: string): Promise<RichiesteClienteModel> {
        return await this.apiService.getAsync<RichiesteClienteModel>(Api.CountRichiesta, { ClienteId });
    }

    async Close(Id: string): Promise<RichiesteClienteModel> {
        return await this.apiServiceExt.getAsync<RichiesteClienteModel>(Api.CloseRichiesta, { Id });
    }
}