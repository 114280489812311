import { ModelBase } from "./ModelBase"


export class StatClienteModel extends ModelBase {
    Cliente: string
    Letto: boolean
}

export class StatLinkClienteModel extends StatClienteModel {
    Cliccato: number
}


export class StatLetturaModel extends ModelBase
{
    Totale: number
    Letti: number
}
