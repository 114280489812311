import * as React from 'react'
import { Card, Form, Input, Row, Col, Button } from 'antd'

import { Store } from 'antd/lib/form/interface';
import SERVICE_IDENTIFIER from '../wires/Identifiers';
import container from '../wires/Bootstrapper';
import { IAccountService } from '../services/AccountService';
import { IAuthService } from '../services/security/AuthService';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
}

const tailLayout = {
    wrapperCol: { offset: 8, span: 14 },
};

export class AppLogin extends React.Component<any, any> {

    service: IAccountService
    authService: IAuthService

    constructor(props) {
        super(props)

        this.service = container.get(SERVICE_IDENTIFIER.ACCOUNT_SERVICE)
        this.authService = container.get(SERVICE_IDENTIFIER.AUTH_SERVICE)
    }

    onLogin = async (values: Store) => {
        let username = values["Username"]
        let password = values["Password"]

        let resp = await this.service.Login(username, password)
        if (resp.IsValid == false) return

        this.authService.signInAndReload(resp)
    }

    render() {
        return (
            <Row justify="center" align="middle">
                <Col span={24} sm={12} xl={6}>
                    <Card title="Login" bordered={false} style={{ marginTop: '24px' }}>
                        <Form {...layout} onFinish={this.onLogin}>
                            <Form.Item name="Username" label="Username" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="Password" label="Password" rules={[{ required: true }]}>
                                <Input.Password />
                            </Form.Item>
                            <Form.Item {...tailLayout}>
                                <Button type="primary" htmlType="submit">Accedi</Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>
        )
    }
}