import { ModelBase, KeyValueModel } from "./ModelBase"
import { ClienteSearcher } from "./ClienteSearcher"
import { ProdottoModel } from "./ProdottoModel"


export class PromozioneBaseModel extends ModelBase {
    Titolo: string
    Descrizione: string
    ResourceId: string
    DataCreazione: Date
    DataValidita: Date
    IsActive: boolean
}

export class PromozioneClienteModel extends PromozioneBaseModel {
    Letto: boolean
}

export class CreaPromozioneModel extends PromozioneBaseModel {
    Link: string
    Prodotti: Array<ProdottoModel> = []
    Destinatario: string

    /// Da considerare solo quando il destinatario è di tipo "Selezione"
    Clienti: Array<string>

    /// Da considerare solo quando il destinatario è di tipo "Filtro"
    Searcher: ClienteSearcher
}

export class RenderCreaPromozioneModel extends CreaPromozioneModel {
    DestinatarioList: Array<KeyValueModel>
}

export class PromozioneModel extends PromozioneBaseModel {
    Link: string
    Prodotti: Array<ProdottoModel>
}